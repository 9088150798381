import {useContext} from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {toast} from 'react-toastify';
import clsx from 'clsx';

import {
  getFactory,
  getFactoryDocumentsNumbers
} from 'redux/slices/factory/selectors';
import {
  copyFactoryCommentFilesAsyncAction,
  deleteFactoryAsyncAction,
  downloadFactoryDocumentAsyncAction,
  fetchFactoryAsyncAction,
  provideFactoryAsyncAction,
  saveFactoryAsyncAction
} from 'redux/slices/factory/factory-api-actions';
import {resetState} from 'redux/slices/factory/factory';
import {redirectToRoute} from 'redux/actions';

import {AppContext} from 'providers/AppContextProvider';

import {getUser} from 'helpers/storage';
import {generateDate} from 'helpers/utils';

import {AppRoute} from 'constants/routes';

import styles from './styles.module.scss';

const FactoryFloatPanel = ({
  setSourceNameNotSelected,
  setTargetNameNotSelected,
  setMaterialNameNotSelected,
  setSourceAmountNotInserted,
  setTargetAmountNotInserted,
  setMaterialRateNotInserted,
  setEmployeeNotInserted,
  setDocumentDateNotSelected,
  setFactoryDateNotSelected,
  setPositionAmountNotInserted,
  setPositionAddressNotSelected,
  setCheckValuesModalData,
  setCheckValuesModalActive,
  setCommentModalActive,
  setIsPreloaderActive
}) => {
  const dispatch = useDispatch();
  const {id} = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const LOCATION_REG_EXP = /archive/;
  const pathName = location.pathname;
  const isInArchive = LOCATION_REG_EXP.test(pathName);

  const {alert} = useContext(AppContext);

  // Проверка уровня пользователя
  const canUserUpdate = getUser().accessLevel > 1;

  const factory = useSelector(getFactory);
  const documentNumber = factory.document_number;
  const documentDate = factory.document_date;
  const newDocumentDate = factory.newDocumentDate;
  const factoryDate = factory.factory_date;
  const provided = !!factory.provided;
  const done = !!factory.done;
  const subName = factory.sub_name;
  const lines = factory.form;
  const materials = factory.materials;
  const author = factory.author;
  const employee = factory.employee;
  const comment = factory.comment;
  const factoryDocumentsNumbers = useSelector(getFactoryDocumentsNumbers);
  const totalBefore = lines && lines.length ? lines.reduce((res, item) => res + item.totalAmount_before, 0) : 0;
  const totalAfter = lines && lines.length ? lines.reduce((res, item) => res + item.totalAmount_after, 0) : 0;

  const createFactory = async () => {
    await dispatch(saveFactoryAsyncAction({
      subName,
			form: lines,
			materials,
			document: {
				number: documentNumber,
				date: newDocumentDate ? `${newDocumentDate}${documentDate.slice(-9)}` : documentDate,
				factoryDate: `${factoryDate.trim()}${documentDate.slice(-9)}`
			},
			employee,
			comment
    }))
    .then((res) => {
      const createdDocumentId = res.payload;

      // Очистить стейт и localStorage от данных нового документа после его отправки на сервер
      dispatch(resetState());
      localStorage.removeItem('newFactoryDocument');

      // Перейти в новый документ
      dispatch(redirectToRoute(`${AppRoute.Document.FACTORIES}/${createdDocumentId}`));
    });
  };

  const updateFactory = async () => {
    try {
      await dispatch(saveFactoryAsyncAction({
        id,
        subName,
        form: lines,
        materials,
        document: {
          number: documentNumber,
          date: documentDate,
          factoryDate: `${factoryDate.trim()} ${documentDate.split(' ')[1]}`
        },
        employee,
        comment
      })).unwrap();

      return true;
    } catch(error) {
      toast.error('Ошибка сохранения!', {
        position: 'bottom-right'
      });

      return false;
    }
  };

  const validateForm = () => {
    let isFormValid = true;

    if (!employee) {
      setEmployeeNotInserted(true);
      isFormValid = false;
    }

    if (!documentDate) {
      setDocumentDateNotSelected(true);
      isFormValid = false;
    }

    if (!factoryDate) {
      setFactoryDateNotSelected(true);
      isFormValid = false;
    }

    // lines.forEach((line) => {
    //   if (!line.name) {
    //     setSourceNameNotSelected(true);
    //     isFormValid = false;
    //   }
    //   if (!line.name_after) {
    //     setTargetNameNotSelected(true);
    //     isFormValid = false;
    //   }
    //   if (!line.totalAmount_before) {
    //     setSourceAmountNotInserted(true);
    //     isFormValid = false;
    //   }
    //   if (!line.totalAmount_after) {
    //     setTargetAmountNotInserted(true);
    //     isFormValid = false;
    //   }
    //   line.userPositions.forEach((position) => {
    //     if (position.address_after && !position.amount_after) {
    //       setPositionAmountNotInserted(true);
    //       isFormValid = false;
    //     }
    //     if (!position.address_after && position.amount_after) {
    //       setPositionAddressNotSelected(true);
    //       isFormValid = false;
    //     }
    //   });
    // });

    // materials.forEach((material) => {
    //   if (!material.name) {
    //     setMaterialNameNotSelected(true);
    //     isFormValid = false;
    //   }

    //   const rateNotInserted = material.userPositions
    //     .every((position) => Number(position.counter) === 0 || !position.counter);
    //   if (rateNotInserted) {
    //     setMaterialRateNotInserted(true);
    //     isFormValid = false;
    //   }
    // });

    return isFormValid;
  };

  const checkFormValues = () => {
    const incorrectLines = {};
    const incorrectMaterials = {};

    lines.forEach((line, index) => {
      const lineNumber = index + 1;

      // Общие проверки
      if (!line.name) {
        if (incorrectLines[lineNumber]) {
          incorrectLines[lineNumber].sourcePlantNotSelected = true;
        } else {
          incorrectLines[lineNumber] = {
            sourcePlantNotSelected: true
          };
        }
      }
      if (!line.name_after) {
        if (incorrectLines[lineNumber]) {
          incorrectLines[lineNumber].targetPlantNotSelected = true;
        } else {
          incorrectLines[lineNumber] = {
            targetPlantNotSelected: true
          };
        }
      }
      if (!line.totalAmount_before || Number(line.totalAmount_before) === 0) {
        if (incorrectLines[lineNumber]) {
          incorrectLines[lineNumber].amountBeforeNotInserted = true;
        } else {
          incorrectLines[lineNumber] = {
            amountBeforeNotInserted: true
          };
        }
      }
      if (!line.totalAmount_after || Number(line.totalAmount_after) === 0) {
        if (incorrectLines[lineNumber]) {
          incorrectLines[lineNumber].amountAfterNotInserted = true;
        } else {
          incorrectLines[lineNumber] = {
            amountAfterNotInserted: true
          };
        }
      }

      // Проверки по позициям
      line.userPositions.forEach((position, positionIndex) => {
        const positionNumber = positionIndex + 1;

        if (Number(position.amount) > Number(line.amounts[positionIndex].amount)) {
          if (incorrectLines[lineNumber]) {
            if (incorrectLines[lineNumber].incorrectUserPositions) {
              incorrectLines[lineNumber].incorrectUserPositions[positionNumber] = {
                amountExceedsExistingValue: true
              };
            } else {
              incorrectLines[lineNumber].incorrectUserPositions = {
                [positionNumber]: {
                  amountExceedsExistingValue: true
                }
              }
            }
          } else {
            incorrectLines[lineNumber] = {
              incorrectUserPositions: {
                [positionNumber]: {
                  amountExceedsExistingValue: true
                }
              }
            };
          }
        }
        if (position.amount_after && Number(position.amount_after) !== 0 && !position.address_after) {
          if (incorrectLines[lineNumber]) {
            if (incorrectLines[lineNumber].incorrectUserPositions) {
              incorrectLines[lineNumber].incorrectUserPositions[positionNumber] = {
                addressNotSelected: true
              };
            } else {
              incorrectLines[lineNumber].incorrectUserPositions = {
                [positionNumber]: {
                  addressNotSelected: true
                }
              }
            }
          } else {
            incorrectLines[lineNumber] = {
              incorrectUserPositions: {
                [positionNumber]: {
                  addressNotSelected: true
                }
              }
            };
          }
        }
        if ((!position.amount_after || Number(position.amount_after) === 0) && position.address_after) {
          if (incorrectLines[lineNumber]) {
            if (incorrectLines[lineNumber].incorrectUserPositions) {
              incorrectLines[lineNumber].incorrectUserPositions[positionNumber] = {
                amountNotInserted: true
              };
            } else {
              incorrectLines[lineNumber].incorrectUserPositions = {
                [positionNumber]: {
                  amountNotInserted: true
                }
              }
            }
          } else {
            incorrectLines[lineNumber] = {
              incorrectUserPositions: {
                [positionNumber]: {
                  amountNotInserted: true
                }
              }
            };
          }
        }
      });

      // Добавить данные растения
      if (incorrectLines[lineNumber]) {
        incorrectLines[lineNumber].plant = line;
      }
    });

    materials.forEach((material, index) => {
      const materialNumber = index + 1;
      const materialRateNotInserted = material.userPositions
        .every((position) => !position.counter || Number(position.counter) === 0);

      // Общие проверки
      if (!material.name) {
        if (incorrectMaterials[materialNumber]) {
          incorrectMaterials[materialNumber].materialNotSelected = true;
        } else {
          incorrectMaterials[materialNumber] = {
            materialNotSelected: true
          };
        }
      }
      if (materialRateNotInserted) {
        if (incorrectMaterials[materialNumber]) {
          incorrectMaterials[materialNumber].materialRateNotInserted = true;
        } else {
          incorrectMaterials[materialNumber] = {
            materialRateNotInserted
          };
        }
      }

      // Проверки по позициям
      material.userPositions.forEach((position, positionIndex) => {
        const positionNumber = positionIndex + 1;

        if (Number(position.amount) > Number(material.amounts[positionIndex].amount)) {
          if (incorrectMaterials[materialNumber]) {
            if (incorrectMaterials[materialNumber].incorrectUserPositions) {
              incorrectMaterials[materialNumber].incorrectUserPositions[positionNumber] = {
                amountExceedsExistingValue: true
              };
            } else {
              incorrectMaterials[materialNumber].incorrectUserPositions = {
                [positionNumber]: {
                  amountExceedsExistingValue: true
                }
              }
            }
          } else {
            incorrectMaterials[materialNumber] = {
              incorrectUserPositions: {
                [positionNumber]: {
                  amountExceedsExistingValue: true
                }
              }
            };
          }
        }
      });

      // Добавить данные материала
      if (incorrectMaterials[materialNumber]) {
        incorrectMaterials[materialNumber].plant = material;
      }
    });

    // Отправить данные для модалки в сеттере
    // Установить флаг открытого положения модалки
    const validationSucceed = !Object.keys(incorrectLines).length && !Object.keys(incorrectMaterials).length;
    if (!validationSucceed) {
      setCheckValuesModalData({
        incorrectLines,
        incorrectMaterials
      });
      setCheckValuesModalActive(true);
    }

    return validationSucceed;
  };

  const compareContainerTypes = () => {
    const sourceGoodsContainers = lines.map((line) => line.name?.split(', ')[1] ? line.name.split(', ')[1] : null);
    const targetGoodsContainers = lines.map((line) => line.name_after?.split(', ')[1] ? line.name_after.split(', ')[1] : null);
    const sourceContainersDiffer = Array.from(new Set(sourceGoodsContainers)).length === 1;
    const targetContainersDiffer = Array.from(new Set(targetGoodsContainers)).length === 1;

    if (!sourceContainersDiffer) {
      toast.error('Контейнеры в исходных растениях не совпадают', {
        position: 'bottom-right'
      });
    }
    if (!targetContainersDiffer) {
      toast.error('Контейнеры в новой продукции не совпадают', {
        position: 'bottom-right'
      });
    }

    return sourceContainersDiffer && targetContainersDiffer;
  };

  // Обработчик кнопки сохранения документа
  const handleSaveButtonClick = () => {
    const isFormValid = validateForm();
    const valuesChecked = isFormValid ? checkFormValues() : false;
    const containersCompared = valuesChecked ? compareContainerTypes() : false
    if (!isFormValid || !valuesChecked || !containersCompared) {
      return;
    }

    const updateHandler = async () => {
      setIsPreloaderActive(true);
      await updateFactory();
      setIsPreloaderActive(false);
    };
    const createHandler = async () => {
      setIsPreloaderActive(true);
      await createFactory();
      setIsPreloaderActive(false);
    };

    alert('Сохранить документ?', 'default', 0, [
      {
        text: 'Да',
        handler: id ? updateHandler : createHandler,
        needToCloseImmediately: true
      },
      {
        text: 'Нет',
        handler: () => alert('', 'default', 1)
      }
    ]);
  };

  // Удаление производства
  const deleteFactory = async () => {
    try {
      await dispatch(deleteFactoryAsyncAction(id));

      return true;
    } catch(error) {
      toast.error('Ошибка удаления!', {
        position: 'bottom-right'
      });

      return false;
    }
  };

  // Обработчик кнопки удаления документа
  const handleDeleteDocumentButtonClick = () => {
    const handler = async () => {
      setIsPreloaderActive(true);

      const deleteResponse = await deleteFactory();
      if (!deleteResponse) {
        setIsPreloaderActive(false);
        return;
      }

      navigate(AppRoute.Document.FACTORIES);
      setIsPreloaderActive(false);
    };

    alert('Удалить производство?', 'default', 0, [
      {
        text: 'Да',
        handler,
        needToCloseImmediately: true
      },
      {
        text: 'Нет',
        handler: () => alert('', 'default', 1)
      }
    ]);
  };

  const provideFactory = async ({factoryId, cancel, complete}) => {
    try {
      await dispatch(provideFactoryAsyncAction({
        factoryId,
        cancel,
        complete
      })).unwrap();

      return true;
    } catch(error) {
      const errorMessage = cancel ? 'Ошибка отмены проведения!' : 'Ошибка проведения!';

      toast.error(errorMessage, {
        position: 'bottom-right'
      });

      return false;
    }
  };

  // Обработчик кнопки первого проведения (в Системе)
  const handleProvideDocumentButtonClick = () => {
    const isFormValid = validateForm();
    const valuesChecked = isFormValid ? checkFormValues() : false;
    const containersCompared = valuesChecked ? compareContainerTypes() : false
    if (!isFormValid || !valuesChecked || !containersCompared) {
      return;
    }

    const handler = async () => {
      setIsPreloaderActive(true);

      const updateFactoryResponse = await updateFactory();
      if (!updateFactoryResponse) {
        setIsPreloaderActive(false);
        return;
      }

      const provideFactoryResponse = await provideFactory({
        factoryId: id,
        cancel: false
      });
      if (!provideFactoryResponse) {
        setIsPreloaderActive(false);
        return;
      }

      await dispatch(fetchFactoryAsyncAction(id));
      setIsPreloaderActive(false);
    };

    alert('Провести в Системе?', 'default', 0, [
      {
        text: 'Да',
        handler,
        needToCloseImmediately: true
      },
      {
        text: 'Нет',
        handler: () => alert('', 'default', 1)
      }
    ]);
  };

  // Обработчик кнопки отмены первого проведения
  const handleDenyDocumentProvidingButtonClick = () => {
    const handler = async () => {
      setIsPreloaderActive(true);

      const denyResponse = await provideFactory({
        factoryId: id,
        cancel: true
      });
      if (!denyResponse) {
        setIsPreloaderActive(false);
        return;
      }

      await dispatch(fetchFactoryAsyncAction(id));
      setIsPreloaderActive(false);
    };

    alert('Отменить проведение в Системе?', 'default', 0, [
      {
        text: 'Да',
        handler,
        needToCloseImmediately: true
      },
      {
        text: 'Нет',
        handler: () => alert('', 'default', 1)
      }
    ]);
  };

  // Обработчик кнопки второго проведения
  const handleMoveToArchiveButtonClick = () => {
    const handler = async () => {
      setIsPreloaderActive(true);

      const provideFactoryResponse = await provideFactory({
        factoryId: id,
        complete: true
      });
      if (!provideFactoryResponse) {
        setIsPreloaderActive(false);
        return;
      }

      navigate(AppRoute.Document.FACTORIES);
      setIsPreloaderActive(false);
    };

    alert('Переместить в Архив?', 'default', 0, [
      {
        text: 'Да',
        handler,
        needToCloseImmediately: true
      },
      {
        text: 'Нет',
        handler: () => alert('', 'default', 1)
      }
    ]);
  };

  // Обработчик кнопки проведения коррекции
  // const handleMakeCorrectionButtonClick = () => {
  //   tmpButtonHandler();
  // };

  // Вычисление минимально возможного (свободного) номера копии
  const getCopyNumber = () => {
    // Чистый номер (без отметки номера копии)
    const currentDocumentNumber = documentNumber.split(' ')[0];

    // Отфильтровать все, которые начинаются с номера текущего документа
    // Сформировать список всех номеров копий
    // Отсортировать номера копий в порядке возрастания
    const currentDocumentNumberCopies = structuredClone(factoryDocumentsNumbers)
      .filter((item) => item.split(' ')[0] === currentDocumentNumber)
      .map((item) => item.split(' ')[2] ? Number(item.split(' ')[2]) : 0)
      .sort((a, b) => a - b);

    // Получить минимально возможный (свободный) номер копии
    const copyNumber = currentDocumentNumberCopies.find((docNumber) => !currentDocumentNumberCopies.includes(docNumber + 1)) + 1;

    return copyNumber;
  };

  // Копирование документа производства
  const copyFactory = async () => {
    const copyNumber = getCopyNumber();

    const copyDocumentNumber = `${documentNumber.split(' ')[0]} копия ${copyNumber}`;
    const copyDocumentDate = generateDate();

    try {
      const id = await dispatch(saveFactoryAsyncAction({
        subName,
        form: lines,
        materials,
        document: {
          number: copyDocumentNumber,
          date: copyDocumentDate,
          factoryDate
        },
        employee,
        comment
      })).unwrap();

      return id;
    } catch(error) {
      toast.error('Ошибка копирования!', {
        position: 'bottom-right'
      });

      return false;
    }
  };

  // Обработчик кнопки копирования документа
  const handleCopyDocumentButtonClick = () => {
    const handler = async () => {
      setIsPreloaderActive(true);

      const updateFactoryResponse = await updateFactory();
      if (!updateFactoryResponse) {
        setIsPreloaderActive(false);
        return;
      }

      const copyFactoryResponse = await copyFactory();
      if (!copyFactoryResponse) {
        setIsPreloaderActive(false);
        return;
      }

      // Скопировать файлы
      const createdDocumentId = copyFactoryResponse;
      dispatch(copyFactoryCommentFilesAsyncAction({
        sourceId: id,
        targetId: createdDocumentId
      }));

      // Очистить стейт и localStorage от данных нового документа после его отправки на сервер
      dispatch(resetState());
      localStorage.removeItem('newFactoryDocument');

      navigate(AppRoute.Document.FACTORIES);
      setIsPreloaderActive(false);
    };

    alert('Копировать производство?', 'default', 0, [
      {
        text: 'Да',
        handler,
        needToCloseImmediately: true
      },
      {
        text: 'Нет',
        handler: () => alert('', 'default', 1)
      }
    ]);
  };

  // Обработчик кнопки модального окна примечания
  const handleCommentModalButtonClick = () => {
    setCommentModalActive(true);
  };

  // Обработчик кнопки скачивания документа
  const handleDownloadDocumentButtonClick = async () => {
    const downloadResponse = await dispatch(downloadFactoryDocumentAsyncAction({
      factory: lines,
      author,
      employee,
      materials,
      document_number: documentNumber,
      document_date: documentDate,
      totalBefore,
      totalAfter
    }));

    window.open(
      `${process.env.REACT_APP_SERVER_URL}/FILES/${downloadResponse.payload.filename}`,
      "_blank"
    );
  };

  const tmpButtonHandler = () => toast.success('Кнопка временно не работает', {
    position: 'bottom-right'
  });

  return (
    <div className={clsx(styles.floatPanel, styles.controlsPanel)}>
      {/* Кнопка удаления документа */}
      {
        id && canUserUpdate && !provided && (
          <span
            className="roundIcon dangerIcon material-icons"
            onClick={handleDeleteDocumentButtonClick}
            title="Удалить"
          >
            delete_sweep
          </span>
        )
      }

      {/* Кнопки проведения документа в системе и отмены проведения */}
      {
        canUserUpdate && provided && !done && (
          <>
            {/* Кнопка отмены проведения */}
            <span
              className="roundIcon material-icons dangerIcon"
              onClick={handleDenyDocumentProvidingButtonClick}
              title="Отменить проведение"
            >
              undo
            </span>
            
            {/* Кнопка проведения в Бизнес.ру */}
            {/* form.provided === 1 */}
            <span
              className="roundIcon material-icons successIcon"
              onClick={handleMoveToArchiveButtonClick}
              title="Второе проведение"
            >
              archive
            </span>
          </>
        )
      }
      {
        id && canUserUpdate && !provided && (
          <span
            className="roundIcon material-icons"
            onClick={handleProvideDocumentButtonClick}
            title="Провести в Системе"
          >
            checklist
          </span>
        )
      }

      {/* Кнопка обновления остатков */}
      {
        // id && canUserUpdate && !provided && (
        //   <span
        //     className="roundIcon material-icons"
        //     onClick={handleRefreshRemainsButtonClick}
        //     title="Обновить остатки"
        //   >
        //     refresh
        //   </span>
        // )
      }

      {/* Кнопка копирования документа */}
      {
        id && canUserUpdate && !provided && (
          <span
            className="roundIcon material-icons"
            onClick={handleCopyDocumentButtonClick}
            title="Скопировать документ"
          >
            copy
          </span>
        )
      }

      {/* Кнопка записи коррекции документа */}
      {/* {
        canUserUpdate && provided && !isInArchive && (
          <span
            className="roundIcon material-icons"
            onClick={handleMakeCorrectionButtonClick}
            title="Записать коррекцию"
          >
            inventory
          </span>
        )
      } */}

      {/* Кнопка сохранения документа */}
      {
        canUserUpdate && !provided && (
          <span
            className="roundIcon material-icons"
            onClick={handleSaveButtonClick}
            title="Сохранить документ"
          >
            save
          </span>
        )
      }

      {/* Кнопка скачивания документа */}
      {
        id && (
          <span
            className="roundIcon material-icons"
            onClick={handleDownloadDocumentButtonClick}
            title="Скачать"
          >
            download
          </span>
        )
      }

      {/* Кнопка открытия модального окна примечания */}
      <span
        className="roundIcon material-icons"
        onClick={handleCommentModalButtonClick}
        title="Примечание"
      >
        maps_ugc
      </span>

      {/* Кнопка перехода в руководство пользователя */}
      <span
        className="roundIcon material-icons"
        onClick={tmpButtonHandler}
        title="Инструкция"
      >
        quiz
      </span>
    </div>
  );
};

export default FactoryFloatPanel;
