import {useContext, useEffect, useState} from 'react';
import {useNavigate, useLocation} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import clsx from 'clsx';

import DocumentsListButtonsBlock from 'components/documents-list-buttons-block/documents-list-buttons-block';
import Modal from 'components/Modal';
// import Button from 'components/ButtonComponent';

import {fetchArchiveSuppliesAsyncAction, fetchSuppliesAsyncAction} from 'redux/slices/supplies/supplies-api-actions';
import {getArchiveSupplies, getSupplies} from 'redux/slices/supplies/selectors';

import {AppContext} from 'providers/AppContextProvider';

import {AppRoute} from 'constants/routes';
import {DocumentType, DocumentTypeNameMap} from 'constants/document-type';

import cl from 'styles/components/cabinet/UsersTable.module.scss';
import styles from './styles.module.scss';

const Index = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const {search} = useLocation();
	const location = useLocation();

	const LOCATION_REG_EXP = /archive/;
  const pathName = location.pathname;
  const isInArchive = LOCATION_REG_EXP.test(pathName);

	const {setCrumbs, setMenuCondition} = useContext(AppContext);

	const supplies = useSelector(getSupplies);
	const archiveSupplies = useSelector(getArchiveSupplies);
	const deniedInBusinessRuSupplies = archiveSupplies.filter((supply) => supply.bru_providing_denied);

	// Модальное окно с отменёнными в Бизнес.ру поступлениями
	const [deniedSuppliesModalActive, setDeniedSuppliesModalActive] = useState(false);

	const handleNavigateToSuppliesArchiveButtonClick = () => {
		window.open(AppRoute.Archive.SUPPLIES);
		setDeniedSuppliesModalActive(false);
	};
	
	// Сброс условия меню
	useEffect(() => {
		setMenuCondition({});
	}, [search]);
	
	// Запись хлебных крошек
	useEffect(() => {
		setCrumbs([
			{name: DocumentTypeNameMap[DocumentType.SUPPLY], url: ''}
		]);
	}, []);

	// Запрос поступлений с сервера
	useEffect(() => {
		dispatch(fetchSuppliesAsyncAction(isInArchive));
		dispatch(fetchArchiveSuppliesAsyncAction());
	}, []);

	// Активация модального окна с отменёнными в Бизнес.ру поступлениями
	useEffect(() => {
		if (deniedInBusinessRuSupplies.length) {
			setDeniedSuppliesModalActive(true);
		}
	}, [deniedInBusinessRuSupplies.length]);

	return (
		<>
			{!supplies.length && <p>&nbsp; Новых поступлений нет</p>}

			{
        !isInArchive && (
          <DocumentsListButtonsBlock
						documentType={DocumentType.SUPPLY}
						withoutNewButton={true}
					/>
        )
      }
			
			<table className={cl.usersTable}>
				<thead>
					<tr>
						<th>№</th>
						<th>Тип</th>
						<th>Дата</th>
						<th>Склад</th>
						<th/>
					</tr>
				</thead>
				<tbody>
					{
						supplies.map((form, index) =>
							<tr key={index.toString()}>
								<td>
									<span>{form.document_number}</span>
								</td>
								<td>
									<span>Поступление</span>
								</td>
								<td>
									<span>{form.document_date.slice(0, -6)}</span>
								</td>
								<td>
									<span>{form.storage ? form.storage : 'НЕ ВЫБРАНО'}</span>
								</td>
								<td>
									<span
										className={cl.icon + ' material-icons'}
										title="Посмотреть"
										onClick={() => navigate(`${AppRoute.Document.SUPPLIES}/${form.supply_id}`)}>
										visibility
									</span>
								</td>
							</tr>
						)
					}
				</tbody>
			</table>

			<Modal
				isActive={deniedSuppliesModalActive}
				setIsActive={setDeniedSuppliesModalActive}
			>
				<div className={styles.wrapper}>
					<p className={styles.title}>
						{'В архиве появились отменённые в Бизнес.ру поступления: '}
					</p>

					<table className={styles.table}>
						<tbody>
							{
								deniedInBusinessRuSupplies.map((deniedSupply, index) => (
									<tr
										key={deniedSupply.supply_id}
										className={styles.deniedSupplyRow}
									>
										<td className={styles.cell}>{index + 1}</td>
										<td className={styles.cell}>{deniedSupply.document_number}</td>
										<td className={styles.cell}>{deniedSupply.document_date}</td>
									</tr>
								))
							}
						</tbody>
					</table>

					{/* Кнопка перехода в архив */}
					<div className={styles.toSuppliesArchiveButtonWrapper}>
						{/* <Button type="white" onClick={handleNavigateToSuppliesArchiveButtonClick}>Архив поступлений</Button> */}
						<button
							className={clsx(styles.button, styles.regular)}
							onClick={handleNavigateToSuppliesArchiveButtonClick}
						>
							<span>
								Архив поступлений
							</span>
						</button>
					</div>
				</div>
			</Modal>
		</>
	);
}

export default Index;
