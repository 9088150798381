import {createAsyncThunk} from '@reduxjs/toolkit';

import {ApiRoute} from 'constants/endpoints';

export const fetchSuppliesAsyncAction = createAsyncThunk(
  'supplies/fetchSupplies',
  async (isInArchive, {extra: api}) => {
    const queryString = isInArchive ? '?done=1' : '';
    const {data} = await api.get(`${ApiRoute.Business.SUPPLIES}${queryString}`);

    return data;
  }
);

export const fetchArchiveSuppliesAsyncAction = createAsyncThunk(
  'supplies/fetchArchiveSupplies',
  async (_args, {extra: api}) => {
    const {data} = await api.get(`${ApiRoute.Business.SUPPLIES}?done=1`);

    return data;
  }
);

export const fetchSupplyDetailsAsyncAction = createAsyncThunk(
  'supplies/fetchSupplyDetails',
  async (id, {extra: api}) => {
    const {data} = await api.get(`${ApiRoute.Business.SUPPLY}/${id}`);

    return data;
  }
);

export const fetchStorageAddressesAsyncAction = createAsyncThunk(
  'supplies/fetchStorageAddresses',
  async (storage, {extra: api}) => {
    const {data} = await api.get(ApiRoute.Map.GET_ALL_ADDRESSES);

    return data;
  }
);

export const saveSupplyAsyncAction = createAsyncThunk(
  'supplies/saveSupply',
  async (args, {extra: api}) => {
    const id = args.id;
    const body = args.body;
    const {error} = await api.post(`${ApiRoute.Business.SAVE_SUPPLY}/${id}`, body);

    return error;
  }
);

export const addSuppliesItemAsyncAction = createAsyncThunk(
  'supplies/addSuppliesItem',
  async (newLine, {extra: api}) => {
    await api.post(ApiRoute.Business.ADD_SUPPLIES_ITEMS, newLine);
  }
);

export const deleteSuppliesItemAsyncAction = createAsyncThunk(
  'supplies/deleteSuppliesItem',
  async (id, {extra: api}) => {
    await api.get(`${ApiRoute.Business.DELETE_SUPPLIES_ITEMS}/${id}`);
  }
);

export const provideSupplyAsyncAction = createAsyncThunk(
  'supplies/provideSupply',
  async (body, {extra: api}) => {
    const {error} = await api.post(ApiRoute.Fields.PROCESS_FORM_WITH_HISTORY_REWRITE, body);

    return error;
  }
);

export const postSupplyCommentFilesAsyncAction = createAsyncThunk(
  'supplies/postCommentFiles',
  async (args, {extra: api}) => {
    const id = args.id;
    const files = args.files;

    await api.post(`${ApiRoute.Business.SET_COMMENT_FILES}/${id}`, files);
  }
);

export const deleteSupplyCommentFileAsyncAction = createAsyncThunk(
  'supplies/deleteCommentFile',
  async (file, {extra: api}) => {
    await api.post(ApiRoute.Business.DELETE_COMMENT_FILE, file);
  }
);
