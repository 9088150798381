export const ApiRoute = {
  Realizations: {
    PARSE_FILE_AND_FETCH_DATA: '/realizations/getRealizationAmountsFromHistory',
    GET_REALIZATION: '/realizations/getRealization',
    GET_REALIZATIONS_LIST: '/realizations/getRealizations',
    GET_ARCHIVE_REALIZATIONS_LIST: '/realizations/getDoneRealizations',
    SAVE_REALIZATION: '/realizations/saveRealizationToFile',
    DELETE_REALIZATION: '/realizations/deleteRealization',
    PROVIDE_REALIZATION: '/realizations/provide',
    SET_DONE: '/realizations/setDone',
    SET_COMMENT_FILE: '/realizations/setFile',
    REMOVE_COMMENT_FILE: '/realizations/removeFile'
  },
  Fields: {
    GET_FORMS: '/fields/getForms',
    GET_FORM: '/fields/getForm',
    SAVE_FORM: '/fields/saveForm',
    UPDATE_FORM: '/fields/editForm',
    DELETE_FORM: '/fields/deleteForm',
    SET_INITIAL_AMOUNTS: '/fields/checkAmount',
    SET_INITIAL_HISTORY_AMOUNTS: '/fields/checkHistoryAmounts',
    REFRESH_AMOUNTS: '/fields/updateFieldsAmountByGoods',
    REFRESH_POSTING_DOCUMENT_AMOUNTS: '/fields/updatePostingDocumentFieldsAmountByGoods',
    PROVIDE_FORM: '/fields/provideForm',
    SEND_COMMENT_FILES: '/fields/setFormFile',
    DELETE_COMMENT_FILE: '/fields/removeFormFile',
    GET_GOODS_BY_STORAGE: '/fields/getGoodsByStorage',
    GET_GOODS_BY_STORAGE_FROM_HISTORY: '/fields/getGoodsByStorageFromHistory',
    GET_ALL_GOODS_FROM_HISTORY: '/fields/getGoodsFromHistory',
    COPY_FORM_FILES: '/fields/copyFormFiles',
    PROCESS_FORM: '/fields/processForm',
    PROCESS_FORM_WITH_HISTORY_REWRITE: '/fields/processFormWithHistoryRewrite',
    DOWNLOAD: '/fields/printForm'
  },
  Map: {
    GET_ALL_MAPS: '/map/getMaps',
    GET_MAP: '/map/getMap',
    GET_ALL_FIELDS: '/map/getAllFields',
    ADD: '/map/add',
    DELETE_FIELD: '/map/deleteField',
    GET_ALL_ADDRESSES: '/map/getAllAddresses',
    EDIT_THUMB: '/map/editThumb'
  },
  History: {
    CURRENT_NAMES: '/history/getCurrentNames',
    ARCHIVE_NAMES: '/history/getArchiveNames',
    ADD_DATA: '/history/addData'
  },
  Business: {
    GROUPS: '/businessRu/getGroups',
    GOODS: '/businessRu/getGoods',
    STORES: '/businessRu/getStores',
    SUPPLIES: '/businessRu/getSupplies',
    SUPPLY: '/businessRu/getSupply',
    SAVE_SUPPLY: '/businessRu/editSupply',
    ADD_SUPPLIES_ITEMS: '/businessRu/addSuppliesItems',
    DELETE_SUPPLIES_ITEMS: '/businessRu/deleteSuppliesItems',
    SET_COMMENT_FILES: '/businessRu/setSupplyCommentFiles',
    DELETE_COMMENT_FILE: '/businessRu/removeSupplyCommentFile'
  },
  Factory: {
    ALL: '/factory/all',
    GET_FACTORY: '/factory/getFactory',
    SAVE: '/factory/save',
    DELETE: '/factory/remove',
    PROVIDE: '/factory/provide',
    SEND_COMMENT_FILES: '/factory/setFile',
    REMOVE_COMMENT_FILE: '/factory/removeFile',
    COPY_FACTORY_COMMENT_FILES: '/factory/copyFactoryFiles',
    DOWNLOAD: '/factory/print'
  },
  Remains: {
    ALL: '/remains/getRemains',
    SHOW: '/remains/getRemainsDocument',
    UPLOAD_REMAINS: '/remains/uploadRemains',
    PROVIDE: '/remains/provideRemains',
    DENY: '/remains/denyRemainsProviding',
    DELETE: '/remains/deleteRemainsDocument',
    CHECK_DENY_POSSIBLE: '/remains/checkRemainsDenyPossible',
    DOWNLOAD: '/remains/downloadRemainsDocument'
  }
};

const Map = {
  GET_ALL_MAPS: '/map/getMaps',
  GET_MAP: '/map/getMap',
  GET_ALL_FIELDS: '/map/getAllFields',
  ADD: '/map/add',
  DELETE_FIELD: '/map/deleteField',
  GET_ALL_ADDRESSES: '/map/getAllAddresses',
  EDIT_THUMB: '/map/editThumb'
};

const fields = {
  HEADS: '/fields/updateFieldHead',
  DATA_LINE: '/fields/updateFieldDataLine',
  DELETE_DATA: '/fields/deleteFieldData',
  PRINT_TABLE: '/fields/printFieldTable',
};

const User = {
  CHECK_AUTH: '/auth/validateToken',
  LOGIN: '/auth/getToken',
  REGISTER: '/auth/register',
  INFO: '/user/info',
};

const journals = {
  AMOUNT_DATA: '/journals/amountData',
  AMOUNT_ALL: '/journals/amountAll',
  JOURNAL_NAME: '/journals/journalName',
  SPREAD_DATA: '/journals/spreadData',
};

const Inventory = {
  ALL: '/inventory/all',
  // POST_FILE: '/inventory/getAmounts', // выборка учётного кол-ва с площадок
  POST_FILE: '/inventory/getAmountsFromHistory', // выборка учётного кол-ва из истории
  ITEMS: '/inventory/getItems',
  EDIT_FILE: '/inventory/editFile',
  SAVE: '/inventory/save',
  EDIT_THUMB: '/inventory/editInventoryThumb',
  PROVIDE: '/inventory/provide',
  EDIT_FILES_BRU: '/inventory/editFilesBru',
  REMOVE: '/inventory/remove',
  ARCHIVES: '/inventory/getArchives',
  COPY: '/inventory/copyInventory',
  SET_COMMENT: '/inventory/setComment',
  SET_COMMENT_FILE: '/inventory/setCommentFile'
};

const History = {
  CURRENT_NAMES: 'history/getCurrentNames',
  ARCHIVE_NAMES: '/history/getArchiveNames'
};

const Good = {
  ALL: '/good/all'
};

const Synchronization = {
  SYNC_VIA_NODE_APP: 'syncViaNodeApp'
};

const business = {
  GROUPS: '/businessRu/getGroups',
  GOODS: '/businessRu/getGoods',
  STORES: '/businessRu/getStores',
  SUPPLIES: '/businessRu/getSupplies',
  SUPPLY: '/businessRu/getSupply',
};

const doc = {
  REALIZATIONS: '/realizations/getRealizations',
  FACTORIES: '/factory/all',
  FACTORY: '/factory/getFactory',
}

export {
  Map,
  fields,
  User,
  journals,
  Inventory,
  History,
  Good,
  business,
  doc,
  Synchronization
};
