import {Fragment, useContext, useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {toast} from 'react-toastify';
import clsx from 'clsx';

import InputComponent from 'components/input/InputComponent';
import CustomSearchSelect from 'components/custom-search-select/custom-search-select';
import InputDate from 'components/input/InputDate';
import PopUp from 'components/popup/popup';

import {
  getAddresses,
  getFactory,
  getFactoryDocumentsNumbers,
  getSourceGoods
} from 'redux/slices/factory/selectors';
import {
  setDocumentDateAction,
  setDocumentNumberAction,
  setFactoryDateAction,
  setFactoryEmployeeAction,
  setFormItemsAction,
  setMaterialsItemsAction,
  setNewDocumentDateAction,
  setSubNameAction
} from 'redux/slices/factory/factory';
import {
  fetchAddressesAsyncAction,
  fetchGoodsFromHistoryAsyncAction,
  setFactorySourceInitialAmountsAsyncAction,
  setMaterialsInitialAmountsAsyncAction
} from 'redux/slices/factory/factory-api-actions';
import {getGoods} from 'redux/slices/good/selectors';
import {fetchGoodsAsyncAction} from 'redux/slices/good/good-api-actions';

import {emptyMaterialLine} from 'constants/factory-materials-empty-line';
import {factoryFormEmptyLine} from 'constants/factory-form-empty-line';

import {
  generateDate,
  getDateFromMySQLDate,
  getDateTimeStringFromDBValue,
  sortStringValues
} from 'helpers/utils';

import {AppContext} from 'providers/AppContextProvider';

import {allowedGroups} from 'constants/document-data';

import cl from 'styles/pages/[fieldId].module.scss';
import colors from 'styles/pages/[boardId].module.scss';

const FactoryTable = ({
  sourceNameNotSelected,
  targetNameNotSelected,
  materialNameNotSelected,
  setSourceNameNotSelected,
  setTargetNameNotSelected,
  setMaterialNameNotSelected,
  sourceAmountNotInserted,
  setSourceAmountNotInserted,
  targetAmountNotInserted,
  setTargetAmountNotInserted,
  materialRateNotInserted,
  setMaterialRateNotInserted,
  employeeNotInserted,
  setEmployeeNotInserted,
  documentDateNotSelected,
  setDocumentDateNotSelected,
  factoryDateNotSelected,
  setFactoryDateNotSelected,
  positionAmountNotInserted,
  setPositionAmountNotInserted,
  positionAddressNotSelected,
  setPositionAddressNotSelected,
  setIsPreloaderActive
}) => {
  const dispatch = useDispatch();
  const {id} = useParams();

  const {alert} = useContext(AppContext);

  const addresses = useSelector(getAddresses);

  const getDisplayedAddresses = (lineTargetAddressNames) => {
    const displayedAddresses = structuredClone(addresses)
      .filter((item) => !lineTargetAddressNames.filter((item) => !!item).includes(item.value))
      .sort((a, b) => {
        const ADDRESS_REG_EXP = /^\d+/;
        const prevValue = a.value;
        const nextValue = b.value;

        if (ADDRESS_REG_EXP.test(prevValue) && ADDRESS_REG_EXP.test(nextValue)) {
          return prevValue.match(ADDRESS_REG_EXP)[0] - nextValue.match(ADDRESS_REG_EXP)[0];
        }
        if (ADDRESS_REG_EXP.test(prevValue) && !ADDRESS_REG_EXP.test(nextValue)) {
          return -1;
        }
        if (!ADDRESS_REG_EXP.test(prevValue) && ADDRESS_REG_EXP.test(nextValue)) {
          return 1;
        }
        
        return 0;
      });

    return displayedAddresses;
  };

  const factory = useSelector(getFactory);
  const documentNumber = factory.document_number;
  const provided = factory.provided;
  const subName = factory.sub_name;
  const documentDate = factory.document_date;
  const newDocumentDate = factory.newDocumentDate;
  const employee = factory.employee;
  const factoryDate = factory.factory_date;
  const lines = factory.form;
  const materials = factory.materials;
  const totalBefore = lines && lines.length ? lines.reduce((res, item) => res + item.totalAmount_before, 0) : 0;
  const totalAfter = lines && lines.length ? lines.reduce((res, item) => res + item.totalAmount_after, 0) : 0;
  const factoryDocumentsNumbers = useSelector(getFactoryDocumentsNumbers);
  const itemsNames = structuredClone(lines).map((itm) => itm.name);

  const sourceGoods = useSelector(getSourceGoods);
  const targetGoods = useSelector(getGoods);

  // Получить список наименований для раздела исходной продукции
  const getSourcePlantsOptions = (group) => {
    if (sourceGoods?.length) {
      const plants = structuredClone(sourceGoods)
        .filter((plant) => {
          if (lines.length > 1 && lines[0].name) {
            if (plant.value?.split(', ')[1]) {
              return plant.value.split(', ')[1] === lines[0].name.split(', ')[1];
            } else {
              return false;
            }
          } else {
            return true;
          }
        })
        .filter((good) => !allowedGroups.includes(good.b_group))
        .map((good) => ({
          name: good.name,
          value: good.name,
          article: good.article,
          good_id: good.b_good_id,
          group: good.b_group
        }));
      const filteredByGroupPlants = plants.filter((good) => group ? good.group === group : true);

      // Отфильтрованные и сформированные наименования и артикулы для отображения в списках селектов
      const plantNameSelectOptions = filteredByGroupPlants.filter((plant) => !itemsNames.includes(plant.name));
      // Удаление дублей в выпадающих списках наименования и артикула
      const uniquePlantNameSelectOptions = [];
      for (let i = 0; i < plantNameSelectOptions.length; i++) {
        const isPlantAlreadyInArray = uniquePlantNameSelectOptions.some((plant) => plant.name === plantNameSelectOptions[i].name);
        if (!isPlantAlreadyInArray) {
          uniquePlantNameSelectOptions.push(plantNameSelectOptions[i]);
        }
      }
      const plantArticleSelectOptions = uniquePlantNameSelectOptions.map((plant) => ({
        name: plant.article,
        value: plant.article
      }));

      // Формирование списка групп для селекта из товаров, которые не включают в себя уже добавленные в документ
      const filteredByDocumentNamesPlants = plants.filter((plant) => !itemsNames.includes(plant.name));
      const groups = Array.from(new Set(filteredByDocumentNamesPlants.map((plant) => plant.group))).map((groupName) => ({
        name: groupName,
        value: groupName
      }));

      return {
        names: uniquePlantNameSelectOptions,
        articles: plantArticleSelectOptions,
        groups
      };
    } else {
      return [];
    }
  };

  // Получить список наименований для раздела новой продукции
  const getTargetPlantsOptions = (selectComponentName) => {
    const clonedTargetGoods = structuredClone(targetGoods);

    const filteredPlants = clonedTargetGoods
      .filter((plant) => {
        if (lines.length > 1 && lines[0].name_after) {
          if (plant.value?.split(', ')[1]) {
            return plant.value.split(', ')[1] === lines[0].name_after.split(', ')[1];
          } else {
            return false;
          }
        } else {
          return true;
        }
      })
      .sort((a, b) => {
        const res = sortStringValues(a.value, b.value);
        return res;
      });
    const filteredArticles = filteredPlants.map((plant) => ({
      id: plant.b_group_id,
      name: plant.article,
      value: plant.article
    }));

    switch(selectComponentName) {
      case 'name_after':
        return filteredPlants;
      case 'article_after':
        return filteredArticles;
      default:
        return;
    };
  };

  // Получить список наименований для раздела материалов
  const getMaterialsOptions = (group) => {
    if (sourceGoods?.length) {
      const plants = structuredClone(sourceGoods)
        .filter((good) => {
          return allowedGroups.includes(good.b_group);
        })
        .map((good) => ({
          name: good.name,
          value: good.name,
          article: good.article,
          good_id: good.b_good_id,
          group: good.b_group
        }));
      const filteredByGroupPlants = plants.filter((good) => group ? good.group === group : true);
      const materialNames = structuredClone(materials).map((item) => item.name);

      // Отфильтрованные и сформированные наименования и артикулы для отображения в списках селектов
      const restMaterialOptions = filteredByGroupPlants.filter((plant) => !materialNames.includes(plant.name));
      // Удаление дублей в выпадающих списках наименования и артикула
      const uniquePlantNameSelectOptions = [];
      for (let i = 0; i < restMaterialOptions.length; i++) {
        const isPlantAlreadyInArray = uniquePlantNameSelectOptions.some((plant) => plant.name === restMaterialOptions[i].name);
        if (!isPlantAlreadyInArray) {
          uniquePlantNameSelectOptions.push(restMaterialOptions[i]);
        }
      }
      const plantArticleSelectOptions = uniquePlantNameSelectOptions.map((plant) => ({
        name: plant.article,
        value: plant.article
      }));

      // Формирование списка групп для селекта из товаров, которые не включают в себя уже добавленные в документ
      const filteredByMaterialsNamesPlants = plants.filter((plant) => !materialNames.includes(plant.name));
      const groups = Array.from(new Set(filteredByMaterialsNamesPlants.map((plant) => plant.group))).map((groupName) => ({
        name: groupName,
        value: groupName
      }));

      return {
        names: uniquePlantNameSelectOptions,
        articles: plantArticleSelectOptions,
        groups
      };
    } else {
      return [];
    }
  };

  // Метод изменения наименования, артикула и группы для исходной продукции
  const selectSourceGood = (index, selectElementName, value) => {
    const copiedLines = structuredClone(lines);
    const plants = structuredClone(sourceGoods).map((good) => ({
      name: good.name,
      value: good.name,
      article: good.article,
      good_id: good.b_good_id,
      group: good.b_group
    }));

    copiedLines[index][selectElementName] = value;

    if (selectElementName === 'name') {
      const plant = plants.find((plant) => plant.name === value);
      const plantArticle = plant.article;
      const businessGroupName = plant.group;
      const plantBruGoodId = plant.good_id;

      copiedLines[index].article = plantArticle;
      copiedLines[index].b_group = businessGroupName;
      copiedLines[index].good_id = plantBruGoodId;
    }

    if (selectElementName === 'article') {
      const plant = plants.find((plant) => plant.article === value);
      const plantName = plant.name;
      const businessGroupName = plant.group;
      const plantBruGoodId = plant.good_id;

      copiedLines[index].name = plantName;
      copiedLines[index].b_group = businessGroupName;
      copiedLines[index].good_id = plantBruGoodId;
    }

    dispatch(setFormItemsAction(copiedLines));

    // Если товар выбран (наименование определено), то запрашиваем данные с полей
    if (copiedLines[index].name) {
      dispatch(setFactorySourceInitialAmountsAsyncAction({
        name: copiedLines[index].name,
        date: newDocumentDate ? `${newDocumentDate}${documentDate.slice(-9)}` : documentDate
      }));
    }
  };

  // Метод изменения наименования, артикула и группы для новой продукции
  const selectTargetGood = (index, selectElementName, value) => {
    const copiedLines = structuredClone(lines);
    const plants = structuredClone(targetGoods).map((good) => ({
      name: good.name,
      value: good.name,
      article: good.article,
      good_id: good.b_good_id,
      group: good.b_group
    }));

    copiedLines[index][selectElementName] = value;

    if (selectElementName === 'name_after') {
      const plant = plants.find((plant) => plant.name === value);
      const plantArticle = plant.article;
      const plantBruGoodId = plant.good_id;

      copiedLines[index].article_after = plantArticle;
      copiedLines[index].good_id_after = plantBruGoodId;
    }

    if (selectElementName === 'article_after') {
      const plant = plants.find((plant) => plant.article === value);
      const plantName = plant.name;
      const plantBruGoodId = plant.good_id;

      copiedLines[index].name_after = plantName;
      copiedLines[index].good_id_after = plantBruGoodId;
    }

    dispatch(setFormItemsAction(copiedLines));
  };

  // Метод изменения наименования, артикула и группы для материала
  const selectMaterial = (index, selectElementName, value) => {
    const updatedMaterials = structuredClone(materials);
    const plants = structuredClone(sourceGoods).map((good) => ({
      name: good.name,
      value: good.name,
      article: good.article,
      good_id: good.b_good_id,
      group: good.b_group
    }));

    updatedMaterials[index][selectElementName] = value;

    if (selectElementName === 'name') {
      const plant = plants.find((plant) => plant.name === value);
      const plantArticle = plant.article;
      const businessGroupName = plant.group;
      const plantBruGoodId = plant.good_id;

      updatedMaterials[index].article = plantArticle;
      updatedMaterials[index].b_group = businessGroupName;
      updatedMaterials[index].good_id = plantBruGoodId;
    }

    if (selectElementName === 'article') {
      const plant = plants.find((plant) => plant.article === value);
      const plantName = plant.name;
      const businessGroupName = plant.group;
      const plantBruGoodId = plant.good_id;

      updatedMaterials[index].name = plantName;
      updatedMaterials[index].b_group = businessGroupName;
      updatedMaterials[index].good_id = plantBruGoodId;
    }

    dispatch(setMaterialsItemsAction(updatedMaterials));

    // Если товар выбран (наименование определено), то запрашиваем данные с полей
    if (updatedMaterials[index].name) {
      dispatch(setMaterialsInitialAmountsAsyncAction({
        name: updatedMaterials[index].name,
        date: newDocumentDate ? `${newDocumentDate}${documentDate.slice(-9)}` : documentDate
      }));
    }
  };

  // Изменение примечания в номере документа (sub_name)
  const handleSubNameNextAreaChange = (evt) => {
    const value = evt.currentTarget.value;
    dispatch(setSubNameAction(value));
  };

  // Установка новой даты документа
  const handleDocumentDateInputChange = (value) => {
    dispatch(setNewDocumentDateAction(value));
  };

  // Ввод имени бригадира
  const handleEmployeeInputChange = (value) => {
    dispatch(setFactoryEmployeeAction(value));
  };

  // Установка даты производства
  const handleFactoryDateInputChange = (value) => {
    const date = getDateFromMySQLDate(value);
    dispatch(setFactoryDateAction(date));
  };

  // Изменение кол-ва используемого сырья
  const handleSourceAmountInputChange = (index, value, totalAddressAmount) => {
    if (!lines[index.index].name) {
      toast.error('Выберите наименование исходного товара', {
        position: 'bottom-right'
      });
      return;
    }

    const amountValue = Number(value) < 0 ? 0 : Number(value);
    const updatedLines = structuredClone(lines);

    // updatedLines[index.index].userPositions[index.ind].amount = amountValue > Number(totalAddressAmount) ? Number(totalAddressAmount) : amountValue;
    updatedLines[index.index].userPositions[index.ind].amount = amountValue;
    updatedLines[index.index].totalAmount_before = updatedLines[index.index].userPositions.reduce((res, item) => res + Number(item.amount), 0);

    dispatch(setFormItemsAction(updatedLines));
  };

  // Изменение кол-ва полученной продукции
  const handleProductAmountInputChange = (index, value) => {
    if (!lines[index.index].name_after) {
      toast.error('Выберите наименование товара для новой продукции', {
        position: 'bottom-right'
      });
      return;
    }

    const amountValue = Number(value) < 0 ? 0 : Number(value);
    const updatedLines = structuredClone(lines);

    updatedLines[index.index].userPositions[index.ind].amount_after = amountValue;
    updatedLines[index.index].totalAmount_after = updatedLines[index.index].userPositions.reduce((res, item) => res + Number(item.amount_after), 0);

    dispatch(setFormItemsAction(updatedLines));
  };

  // Изменение кол-ва материала
  const handleMaterialAmountInputChange = (indexes, value) => {
    if (!materials[indexes.index].name) {
      toast.error('Выберите наименование материла', {
        position: 'bottom-right'
      });
      return;
    }

    const updatedMaterials = structuredClone(materials);
    // const addressExistingAmount = Number(updatedMaterials[indexes.index].amounts[indexes.ind].amount);
    const assignedValue = Number(value) > 0 ? Number(value) : 0;
    const totalAmountAfter = lines.reduce((res, item) => res + Number(item.totalAmount_after), 0);

    updatedMaterials[indexes.index].userPositions[indexes.ind].counter = assignedValue;
    updatedMaterials[indexes.index].userPositions[indexes.ind].amount = assignedValue * totalAmountAfter;

    dispatch(setMaterialsItemsAction(updatedMaterials));
    setMaterialRateNotInserted(false);
  };

  // Выбор адреса поступления продукции
  const handleProductAddressInputChange = (index, value) => {
    const updatedLines = structuredClone(lines);

    updatedLines[index.index].userPositions[index.ind].address_after = value;

    dispatch(setFormItemsAction(updatedLines));
  };

  // Добавление новой строки в таблице материалов
  const handleAddMaterialButtonClick = () => {
    if (getMaterialsOptions('')?.names?.length === 0) {
      toast.error('Больше нет доступных материалов на складе', {
        position: 'bottom-right'
      });
      return;
    }

    if (!materials[materials.length - 1].name) {
      toast.error('Выберите наименование материала в последней строке', {
        position: 'bottom-right'
      });
      return;
    }

    const updatedMaterials = structuredClone(materials);

    updatedMaterials.push(emptyMaterialLine);

    dispatch(setMaterialsItemsAction(updatedMaterials));
  };

  // Обработчик кнопки удаления строки в таблице материалов
  const handleDeleteMaterialRowButtonClick = (index) => {
    if (materials.length === 1 && !materials[0].name) {
      return;
    }

    const handler = () => {
      const updatedMaterials = structuredClone(materials);

      updatedMaterials.splice(index, 1);

      if (!updatedMaterials.length) {
        updatedMaterials.push(emptyMaterialLine);
      }

      dispatch(setMaterialsItemsAction(updatedMaterials));
    };

    alert('Удалить материал?', 'danger', 0, [
      {
        text: 'Да',
        handler,
        needToCloseImmediately: true
      },
      {
        text: 'Нет',
        handler: () => alert('', 'default', 1)
      }
    ]);
  };

  // Обработчик добавления подстроки для продукции
  const handleAddNewSubstringButtonClick = (index) => {
    if (lines[index].userPositions.length === 1 && !lines[index].name) {
      toast.error('Выберите наименование исходного товара перед добавлением новой строки', {
        position: 'bottom-right'
      });
      return;
    }

    const updatedLines = structuredClone(lines);

    updatedLines[index].userPositions = [
      ...updatedLines[index].userPositions,
      {
        address: '',
        amount: '',
        address_after: '',
        amount_after: ''
      }
    ];

    dispatch(setFormItemsAction(updatedLines));
  };

  // Обработчик удаления подстроки из продукции
  const handleDeleteSubstringButtonClick = (indexes) => {
    if (lines.length === 1 && !lines[0].name) {
      return;
    }

    const updatedLines = structuredClone(lines);
    const updatedPositions = structuredClone(updatedLines[indexes.index].userPositions);
    const availablePositions = updatedPositions.filter((item) => item.address);

    const handler = () => {
      if (updatedPositions.length === 1 || (availablePositions.length === 1 && updatedPositions[indexes.ind].address)) {
        updatedLines.splice(indexes.index, 1);
        dispatch(setFormItemsAction(updatedLines));
      } else {
        updatedPositions.splice(indexes.ind, 1);
        updatedLines[indexes.index].userPositions = updatedPositions;

        dispatch(setFormItemsAction(updatedLines));
      }

      alert('', 'default', 1);
    };

    let alertMessage = updatedPositions.length === 1 ? 'Удалить товар?' : 'Удалить строку?';
    // Если всего позиций более, чем 1, но при этом с действующим адресом остался последний
    // и при этом удаляется именно он, то предупреждаем, что будет удалён товар целиком
    if (updatedPositions.length > 1 && availablePositions.length === 1 && updatedPositions[indexes.ind].address) {
      alertMessage = <span>После удаления этой строки у вас не останется<br/>исходного товара для производства новой продукции.<br/>Придётся удалить товар целиком. Удалить?</span>;
    }

    alert(alertMessage, 'danger', 0, [
      {
        text: 'Да',
        handler,
        needToCloseImmediately: true
      },
      {
        text: 'Нет',
        handler: () => alert('', 'default', 1)
      }
    ]);
  };

  // Генерация номера нового документа
  const generateDocumentNumber = () => {
    const existingDocumentsNumbers = factoryDocumentsNumbers
      .filter((docNumber) => (/\d+/).test(String(docNumber)))
      .map((docNumber) => Number(String(docNumber).match(/\d+/)[0]))
      .sort((a, b) => a - b);
    const newNumber = [0, ...existingDocumentsNumbers]
      .find((docNumber) => !existingDocumentsNumbers.includes(docNumber + 1)) + 1;

    return newNumber;
  };

  // Обработчик клика по неактивной дате документа
  const handleInactiveDocumentDateClick = () => {
    toast.error('Дата сохранена. Если нужна другая дата - создайте новый документ', {
      position: 'top-left'
    });
  };

  // Добавление нового товара (строки) в основной таблице
  const handleAddNewGoodString = () => {
    if (!lines[lines.length - 1].name) {
      toast.error(`Выберите наименование исходного растения в${lines.length > 1 ? ' последней' : ''} строке`, {
        position: 'bottom-right'
      });
      return;
    }
    if (!lines[lines.length - 1].name_after) {
      toast.error(`Выберите наименование продукции${lines.length > 1 ? ' последней' : ''} строке`, {
        position: 'bottom-right'
      });
      return;
    }

    const updatedLines = structuredClone(lines);

    updatedLines.push(factoryFormEmptyLine);

    dispatch(setFormItemsAction(updatedLines));
  };

  // Пересчитывание кол-ва материалов при изменении кол-ва новой продукции
  useEffect(() => {
    const updatedMaterials = structuredClone(materials)
      .map((material) => {
        const materialUserPositions = structuredClone(material.userPositions)
          .map((position) => ({
            ...position,
            amount: position.counter * totalAfter
          }));;
        const updatedMaterials = {
          ...material,
          userPositions: materialUserPositions
        };

        return updatedMaterials;
      });

    dispatch(setMaterialsItemsAction(updatedMaterials));
  }, [totalAfter]);

  // Запрос товаров по конкретному складу при его изменении в выпадающем списке
  // (для формирования выпадающих списков выбора наименования, артикула и группы)
  useEffect(() => {
    if (documentDate) {
      dispatch(fetchGoodsFromHistoryAsyncAction({
        date: newDocumentDate ? `${newDocumentDate}${documentDate.slice(-9)}` : documentDate
      }));
    }
    if (!targetGoods.length) {
      dispatch(fetchGoodsAsyncAction());
    }
  }, [documentDate, newDocumentDate]);

  // Установка номера для нового документа
  useEffect(() => {
    if (!id) {
      if (!documentNumber) {
        const newDocumentNumber = generateDocumentNumber();
        dispatch(setDocumentNumberAction(newDocumentNumber));
      }
    }
  }, [factoryDocumentsNumbers]);

  // Установка даты для нового документа
  useEffect(() => {
    if (!id) {
      if (!documentDate) {
        const generatedDate = generateDate();
        dispatch(setDocumentDateAction(generatedDate));
      }
    }
  }, []);

  // Если документ новый и строк пока
  // нет, то добавляем пустые строки
  useEffect(() => {
    if (!lines.length) {
      dispatch(setFormItemsAction([factoryFormEmptyLine]));
    }
    if (!materials || !materials.length) {
      dispatch(setMaterialsItemsAction([emptyMaterialLine]));
    }
  }, []);

  useEffect(() => {
    dispatch(fetchAddressesAsyncAction());
  }, []);

  return (
    <div className="stickyContainer">
      <table
        className={clsx(
          cl.mainTable,
          cl.top0,
          cl.overflow,
          'table',
          'table-responsive'
        )}
        style={{zIndex: "30"}}
      >
        <thead className={clsx(cl.top0, 'theadBordered', 'thead-dark')}>
          <tr>

            {/* Номер документа и subName-примечание */}
            <th colSpan="2" className={cl.borderNone}>
              {
                !!documentNumber && (
                  <>Производство № {documentNumber}<br/></>
                )
              }

              {
                !provided ? (
                  <textarea
                    name="subName"
                    placeholder="Примечание"
                    value={subName}
                    style={{marginTop: "8px"}}
                    onChange={handleSubNameNextAreaChange}
                  />
                ) : (
                  <b>{subName}</b>
                )
              }
            </th>

            {/* Дата документа */}
            <th className={clsx(cl.borderNone)} style={{padding: '10px'}}>
              <span>от&nbsp;</span>

              {
                !provided && lines.length === 1 && !lines[0].name ? (
                  <InputComponent
                    type="text"
                    name="documentDate"
                    autocomplete="off"
                    mask="99.99.9999"
                    placeholder="Дата"
                    value={newDocumentDate ? newDocumentDate : documentDate}
                    setValue={(name, value) => handleDocumentDateInputChange(value)}
                  />
                ) : (
                  <span onClick={handleInactiveDocumentDateClick}>
                    {newDocumentDate ? newDocumentDate : documentDate.split(' ')[0]}
                  </span>
                )
              }
              {
                documentDateNotSelected && !employee && (
                  <PopUp setPopUpVisible={setDocumentDateNotSelected}>
                    <span>
                      Укажите дату документа
                    </span>
                  </PopUp>
                )
              }
            </th>

            {/* Автор и Ответственный */}
            <th style={{padding: '10px'}}>
              {
                factory.author && (
                  <>
                    Автор:&nbsp;
                    {factory.author}
                    <br/>
                  </>
                )
              }
              Ответственный:&nbsp;
              {
                provided ? (
                  <span>{employee}</span>
                ) : (
                  <InputComponent
                    type="text"
                    name="employee"
                    value={employee}
                    setValue={(name, value) => handleEmployeeInputChange(value)}
                    placeholder="Ответственный"
                    title={employee}
                  />
                )
              }
              {
                employeeNotInserted && !employee && (
                  <PopUp setPopUpVisible={setEmployeeNotInserted}>
                    <span>
                      Укажите имя бригадира
                    </span>
                  </PopUp>
                )
              }
            </th>

            <th colSpan="3" className={colors.b5}>В наличии</th>
            <th colSpan="3" className={colors.b7}>Используемый материал</th>

            {/* Дата производства factoryDate */}
            <th colSpan="5" className={clsx(cl.dateInput, colors.b5)}>
              Продукция план:<br/>
              {
                !id || provided === 0 ? (
							    <InputDate
                    name="factoryDate"
                    currentValue={factoryDate ? getDateTimeStringFromDBValue(factoryDate).split('T')[0] : ''}
                    changeHandler={(name, value) => handleFactoryDateInputChange(value)}
                  />
                ) : (
                  <span>{factoryDate.split(' ')[0]}</span>
                )
              }
              {
                factoryDateNotSelected && !factoryDate && (
                  <PopUp setPopUpVisible={setFactoryDateNotSelected}>
                    <span>
                      Укажите дату производства
                    </span>
                  </PopUp>
                )
              }
            </th>
            {
              !provided && (
                <th className={cl.borderNone} rowSpan="3" colSpan="2"/>
              )
            }
          </tr>

          {/* Названия столбцов */}
          <tr>
            <th rowSpan="2">№</th>
            <th
              rowSpan="2"
              // className={sorted === "name" ? "sort sorted" : "sort"}
              // onClick={() => sortPlants("name")}
            >
              <span>Название</span>
            </th>
            <th
              rowSpan="2"
              // className={sorted === "article" ? "sort sorted" : "sort"}
              // onClick={() => sortPlants("article")}
            >
              <span>Артикул</span>
            </th>
            <th rowSpan="2">
              Группа
            </th>
            <th rowSpan="2" className={colors.b5}>Всего:</th>
            <th colSpan="2" className={colors.b5}>В том числе:</th>
            <th rowSpan="2" className={colors.b7}>Всего:</th>
            <th colSpan="2" className={colors.b7}>В том числе:</th>
            <th rowSpan="2" className={colors.b5}>Название</th>
            <th rowSpan="2" className={colors.b5}>Артикул</th>
            <th rowSpan="2" className={colors.b5}>Всего:</th>
            <th colSpan="2" className={colors.b5}>В том числе:</th>
          </tr>
          <tr>
            <th className={colors.b5}>Кол-во</th>
            <th className={colors.b5}>Адрес</th>
            <th className={colors.b7}>Кол-во</th>
            <th className={colors.b7}>Адрес</th>
            <th className={colors.b5}>Кол-во</th>
            <th className={colors.b5}>Адрес</th>
          </tr>

          {/* Нумерация столбцов */}
          <tr>
            <th>1</th>
            <th>2</th>
            <th>3</th>
            <th>4</th>
            <th className={colors.b5} colSpan={3}>5</th>
            <th className={colors.b7} colSpan={3}>6</th>
            <th className={colors.b5}>7</th>
            <th className={colors.b5}>8</th>
            <th className={colors.b5} colSpan={3}>9</th>
            <th className={cl.borderNone} colSpan={2}/>
          </tr>
        </thead>
        <tbody>
          {
            lines && lines.length && lines.map((line, index) =>
              <Fragment key={index.toString()}>
                {
                  line.userPositions.map((position, positionIndex) =>
                    <tr key={positionIndex.toString()} className={line.isError ? cl.error : ''}>


                      {/* Левая белая и зелёная области таблицы */}
                      <>
                        {
                          positionIndex === 0 && (
                            <>
                              <td rowSpan={line.userPositions.length}>{index + 1}</td>
                              <td
                                rowSpan={line.userPositions.length}
                                className={cl.name}
                              >
                                {
                                  !provided && !line.name ? (
                                    <CustomSearchSelect
                                      inputName='name'
                                      defaultValue={line.name}
                                      options={getSourcePlantsOptions(line.b_group)?.names}
                                      onChange={(value) => selectSourceGood(index, 'name', value)}
                                      placeholder='Наименование'
                                      searchMode={true}
                                    />
                                  ) : (
                                    line.name
                                  )
                                }
                                {
                                  sourceNameNotSelected && !line.name && (
                                    <PopUp setPopUpVisible={setSourceNameNotSelected}>
                                      <span>
                                        Выберите наименование
                                      </span>
                                    </PopUp>
                                  )
                                }
                              </td>
                              <td rowSpan={line.userPositions.length}>
                                {
                                  !provided && !line.article ? (
                                    <CustomSearchSelect
                                      inputName='article'
                                      defaultValue={line.article}
                                      options={getSourcePlantsOptions(line.b_group)?.articles}
                                      onChange={(value) => selectSourceGood(index, 'article', value)}
                                      placeholder='Артикул'
                                      searchMode={true}
                                    />
                                  ) : (
                                    line.article
                                  )
                                }
                              </td>
                              <td rowSpan={line.userPositions.length}>
                                {
                                  !provided && !line.b_group ? (
                                    <CustomSearchSelect
                                      inputName='b_group'
                                      defaultValue={line.b_group}
                                      options={getSourcePlantsOptions(line.b_group)?.groups}
                                      onChange={(value) => selectSourceGood(index, 'b_group', value)}
                                      placeholder='Группа'
                                      searchMode={true}
                                    />
                                  ) : (
                                    line.b_group
                                  )
                                }
                              </td>
                              <td
                                rowSpan={line.userPositions.length}
                                className={clsx(cl.right, colors.b5, cl.date)}
                              >
                                {new Intl.NumberFormat("ru-RU").format(line.totalAmount)}
                              </td>
                            </>
                          )
                        }
                        
                        <td className={clsx(cl.right, colors.b5, cl.date)}>
                          {new Intl.NumberFormat("ru-RU").format(line.amounts.find((item) => item.address === position.address) ? line.amounts.find((item) => item.address === position.address).amount : 0)}
                        </td>
                        <td className={clsx(colors.b5, cl.date)}>
                          {!position.address.split('.')[0] ? '' : position.address}
                        </td>
                      </>



                      {/* Синяя область таблицы */}
                      <>
                        {
                          positionIndex === 0 && (
                            <td
                              rowSpan={line.userPositions.length}
                              className={clsx(cl.right, colors.b7, cl.date)}
                            >
                              {new Intl.NumberFormat("ru-RU").format(line.totalAmount_before)}
                            </td>
                          )
                        }
                        <td className={clsx(cl.right, colors.b7, cl.date)}>
                          {
                            !provided && line.amounts.find((item) => item.address === position.address) ? (
                              <InputComponent
                                type="number"
                                name="amount"
                                placeholder="Кол-во"
                                value={position.amount}
                                setValue={(name, value) => handleSourceAmountInputChange({index, ind: positionIndex}, value, line.amounts.find((item) => item.address === position.address).amount)}
                              />
                            ) : (
                              new Intl.NumberFormat("ru-RU").format(position.amount)
                            )
                          }
                        </td>
                        <td className={clsx(colors.b7, cl.date)}>
                          {!position.address.split('.')[0] ? '' : position.address}
                        </td>
                      </>



                      {/* Правая зелёная область таблицы */}
                      <>
                        {
                          positionIndex === 0 && (
                            <>
                              <td
                                rowSpan={line.userPositions.length}
                                className={clsx(cl.name, colors.b5)}
                              >
                                {
                                  !provided && !line.name_after ? (
                                    <CustomSearchSelect
                                      inputName='name_after'
                                      defaultValue={line.name_after}
                                      options={getTargetPlantsOptions('name_after')}
                                      onChange={(value) => selectTargetGood(index, 'name_after', value)}
                                      placeholder='Наименование'
                                      searchMode={true}
                                    />
                                  ) : (
                                    line.name_after
                                  )
                                }
                                {
                                  targetNameNotSelected && !line.name_after && (
                                    <PopUp setPopUpVisible={setTargetNameNotSelected}>
                                      <span>
                                        Выберите наименование
                                      </span>
                                    </PopUp>
                                  )
                                }
                              </td>
                              <td
                                rowSpan={line.userPositions.length}
                                className={colors.b5}
                              >
                                {
                                  !provided && !line.article_after ? (
                                    <CustomSearchSelect
                                      inputName='article_after'
                                      defaultValue={line.article_after}
                                      options={getTargetPlantsOptions('article_after')}
                                      onChange={(value) => selectTargetGood(index, 'article_after', value)}
                                      placeholder='Артикул'
                                      searchMode={true}
                                    />
                                  ) : (
                                    line.article_after
                                  )
                                }
                              </td>
                              <td
                                rowSpan={line.userPositions.length}
                                className={clsx(cl.right, colors.b5, cl.date)}
                              >
                                {
                                  !isNaN(parseInt(line.totalAmount_after))
                                    ? new Intl.NumberFormat("ru-RU").format(line.totalAmount_after)
                                    : line.totalAmount_after
                                }
                              </td>
                            </>
                          )
                        }
                        
                        <td className={clsx(cl.right, colors.b5, cl.date)}>
                          {
                            !provided ? (
                              <InputComponent
                                type="number"
                                name="amount_after"
                                placeholder="Кол-во"
                                value={position.amount_after}
                                setValue={(name, value) => handleProductAmountInputChange({index, ind: positionIndex}, value)}
                              />
                            ) : (
                              !position.amount_after
                                ? 0
                                : new Intl.NumberFormat("ru-RU").format(position.amount_after)
                            )
                          }
                          {
                            positionAmountNotInserted && position.address_after && !position.amount_after && (
                              <PopUp
                                position='top-right'
                                setPopUpVisible={setPositionAmountNotInserted}
                              >
                                <span>
                                  Укажите количество
                                </span>
                              </PopUp>
                            )
                          }
                        </td>
                        <td className={clsx(colors.b5, cl.date)}>
                          {
                            !provided && line.name_after ? (
                              <CustomSearchSelect
                                inputName='address_after'
                                defaultValue={position.address_after}
                                options={getDisplayedAddresses(line.userPositions.filter((_p, index) => index !== positionIndex).map((position) => position.address_after))}
                                onChange={(value) => handleProductAddressInputChange({index, ind: positionIndex}, value)}
                                placeholder='Адрес'
                                executeChangeHandlerOnTypingValue
                                searchMode
                              />
                            ) : (
                              position.address_after
                            )
                          }
                          {
                            positionAddressNotSelected && position.amount_after && !position.address_after ? (
                              <PopUp
                                position='top-right'
                                setPopUpVisible={setPositionAddressNotSelected}
                              >
                                <span>
                                  Выберите адрес
                                </span>
                              </PopUp>
                            ) : null
                          }
                        </td>
                      </>



                      {
                        !provided && (
                          <td className="iconed">
                            <span
                              className="roundIcon material-icons"
                              onClick={() => handleDeleteSubstringButtonClick({index, ind: positionIndex})}
                              title="Удалить подстроку"
                            >
                              delete_outline
                            </span>
                          </td>
                        )
                      }
                      
                      {
                        positionIndex === 0 && !provided && (
                          <td rowSpan={line.amounts.length} className="iconed">
                            <span
                              className="roundIcon material-icons"
                              onClick={() => handleAddNewSubstringButtonClick(index)}
                              title="Добавить подстроку"
                            >
                              add
                            </span>
                          </td>
                        )
                      }
                    </tr>
                  )
                }
              </Fragment>
            )
          }
          
          <tr>
            <td className={cl.borderNone} colSpan={7}/>
            <td className={clsx(cl.right, colors.b7, colors.bold, cl.date)}>
              {new Intl.NumberFormat("ru-RU").format(totalBefore)}
              {
                sourceAmountNotInserted && !totalBefore && (
                  <PopUp setPopUpVisible={setSourceAmountNotInserted}>
                    <span>
                      Укажите количество
                    </span>
                  </PopUp>
                )
              }
            </td>
            <td className={cl.borderNone} colSpan={2}/>
            <td className={clsx(cl.right, colors.b5, colors.bold)} colSpan="2">
              Итого новой продукции, шт.:
            </td>
            <td className={clsx(cl.right, colors.b5, colors.bold, cl.date)}>
              {
                !isNaN(parseInt(totalAfter))
                  ? new Intl.NumberFormat("ru-RU").format(totalAfter)
                  : 0
              }
              {
                targetAmountNotInserted && !totalAfter && (
                  <PopUp setPopUpVisible={setTargetAmountNotInserted}>
                    <span>
                      Укажите количество
                    </span>
                  </PopUp>
                )
              }
            </td>
            <td className={cl.borderNone} colSpan={4}/>
          </tr>
          {
            !provided && (
              <tr className="adding">
                <td className="iconed">
                  <span
                    className="roundIcon material-icons"
                    onClick={handleAddNewGoodString}
                    title="Добавить строку"
                  >
                    add
                  </span>
                </td>
              </tr>
            )
          }
          <tr style={{height: "50px"}}/>
        </tbody>

        {/* Таблица Материалы */}
        <thead className={clsx(cl.top0, 'theadBordered', 'thead-dark')}>
          <tr>
            <th rowSpan="2">№</th>
            <th
              rowSpan="2"
              // className={sortedMaterials === "name" ? "sort sorted" : "sort"}
              // onClick={() => sortMaterials("name")}
            >
              <span>Название</span>
            </th>
            <th
              rowSpan="2"
              // className={sortedMaterials === "article" ? "sort sorted" : "sort"}
              // onClick={() => sortMaterials("article")}
            >
              <span>Артикул</span>
            </th>
            <th rowSpan="2">Группа</th>
            <th rowSpan="2" className={colors.b5}>Всего:</th>
            <th colSpan="2" className={colors.b5}>В том числе:</th>
            <th colSpan="3" className={colors.b7}>Расходные материалы (план)</th>
          </tr>
          <tr>
            <th className={colors.b5}>Кол-во</th>
            <th className={colors.b5}>Адрес</th>
            <th className={colors.b7}>На ед.</th>
            <th className={colors.b7}>Всего</th>
            <th className={colors.b7}>Ед. изм.</th>
          </tr>
        </thead>
        <tbody>
          {
            materials && materials.map((material, index) =>
              <Fragment key={index}>
                {
                  material.amounts.map((mt, ind) =>
                    <tr key={ind} className={material.isError ? cl.error : ''}>
                      {
                        ind === 0 && (
                          <>
                            <td rowSpan={material.amounts.length}>{index + 1}</td>
                            <td rowSpan={material.amounts.length}>
                              {
                                !provided && !material.name  ? (
                                  <CustomSearchSelect
                                    inputName='name'
                                    defaultValue={material.name}
                                    options={getMaterialsOptions(material.b_group)?.names}
                                    onChange={(value) => selectMaterial(index, 'name', value)}
                                    placeholder='Наименование материала'
                                    searchMode={true}
                                  />
                                ) : (
                                  material.name
                                )
                              }
                              {
                                  materialNameNotSelected && !material.name && (
                                    <PopUp setPopUpVisible={setMaterialNameNotSelected}>
                                      <span>
                                        Выберите наименование
                                      </span>
                                    </PopUp>
                                  )
                                }
                            </td>
                            <td rowSpan={material.amounts.length}>
                              {
                                !provided && !material.article ? (
                                  <CustomSearchSelect
                                    inputName='article'
                                    defaultValue={material.article}
                                    options={getMaterialsOptions(material.b_group)?.articles}
                                    onChange={(value) => selectMaterial(index, 'article', value)}
                                    placeholder='Артикул'
                                    searchMode={true}
                                  />
                                ) : (
                                  material.article
                                )
                              }
                            </td>
                            <td rowSpan={material.amounts.length}>
                              {
                                !provided && !material.b_group ? (
                                  <CustomSearchSelect
                                    inputName='b_group'
                                    defaultValue={material.b_group}
                                    options={getMaterialsOptions(material.b_group)?.groups}
                                    onChange={(value) => selectMaterial(index, 'b_group', value)}
                                    placeholder='Группа'
                                    searchMode={true}
                                  />
                                ) : (
                                  material.b_group
                                )
                              }
                            </td>
                            <td
                              rowSpan={material.amounts.length}
                              className={clsx(cl.right, colors.b5)}
                              style={{width: "9%"}}
                            >
                              {new Intl.NumberFormat("ru-RU").format(material.totalAmount)}
                            </td>
                          </>
                        )
                      }
                      
                      <td className={clsx(cl.right, colors.b5)}>
                        {new Intl.NumberFormat("ru-RU").format(mt.amount)}
                      </td>
                      <td className={colors.b5}>{mt.address}</td>
                      
                      <td className={clsx(cl.right, colors.b7)}>
                        {
                          !provided ? (
                            <InputComponent
                              type="number"
                              name="counter"
                              placeholder="На ед."
                              value={material.userPositions[ind].counter}
                              setValue={(name, value) => handleMaterialAmountInputChange({index, ind}, value)}
                            />
                          ) : (
                            new Intl.NumberFormat("ru-RU").format(material.userPositions[ind].counter)
                          )
                        }
                        {
                          ind === material.userPositions.length - 1 &&
                          materialRateNotInserted &&
                          (!material.userPositions[ind].counter || Number(material.userPositions[ind].counter) === 0) && (
                            <PopUp setPopUpVisible={setMaterialRateNotInserted}>
                              <span>
                                Укажите норму расхода
                              </span>
                            </PopUp>
                          )
                        }
                      </td>
                      <td className={clsx(cl.right, colors.b7)}>
                        {new Intl.NumberFormat("ru-RU").format(material.userPositions[ind].amount)}
                      </td>
                      
                      {
                        ind === 0 && (
                          <td
                            rowSpan={material.amounts.length}
                            className={colors.b7}
                          >
                            {material.measure}
                          </td>
                        )
                      }
                      
                      {
                        !provided && ind === 0 && (
                          <td
                            rowSpan={material.amounts.length}
                            className={`iconed ${cl.left}`}
                          >
                            <span
                              className="roundIcon material-icons"
                              onClick={() => handleDeleteMaterialRowButtonClick(index)}
                              title="Удалить строку"
                            >
                              delete_outline
                            </span>
                        </td>
                        )
                      }
                    </tr>
                  )
                }
              </Fragment>
            )
          }
          
          {
            !provided && (
              <tr className="adding">
                <td className="iconed">
                  <span
                    className="roundIcon material-icons"
                    onClick={handleAddMaterialButtonClick}
                    title="Добавить материал"
                  >
                    add
                  </span>
                </td>
              </tr>
            )
          }
        </tbody>
      </table>
    </div>
  );
};

export default FactoryTable;
