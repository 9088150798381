import {createSlice} from '@reduxjs/toolkit';

import {
  fetchArchiveSuppliesAsyncAction,
  fetchStorageAddressesAsyncAction,
  fetchSuppliesAsyncAction,
  fetchSupplyDetailsAsyncAction
} from './supplies-api-actions';

const initialState = {
  supplies: [],
  archiveSupplies: [],
  supply: {
    supply_id: '',
    document_number: '',
    document_date: '',
    storage: '',
    provided: '',
    done: undefined,
    author: '',
    employee: '',
    comment: '',
    form: []
  },
  newSupplyDate: '',
  commentFiles: [],
  addresses: []
};

export const supplies = createSlice({
  name: 'supplies',
  initialState,
  reducers: {
    resetState: (state) => {
      state.supply = {
        supply_id: '',
        document_number: '',
        document_date: '',
        storage: '',
        provided: '',
        done: undefined,
        author: '',
        employee: '',
        comment: '',
        form: []
      };
      state.newSupplyDate = '';
      state.commentFiles = [];
    },
    setSupplyCommentAction: (state, action) => {
      state.supply.comment = action.payload;
    },
    setCommentFilesAction: (state, action) => {
      state.commentFiles = action.payload;
    },
    setSupplyStorageAction: (state, action) => {
      state.supply.storage = action.payload;
    },
    setNewSupplyDateAction: (state, action) => {
      state.newSupplyDate = action.payload;
    },
    setSupplyEmployeeAction: (state, action) => {
      state.supply.employee = action.payload;
    },
    setSupplyFormAction: (state, action) => {
      state.supply.form = action.payload;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(fetchSuppliesAsyncAction.fulfilled, (state, action) => {
        state.supplies = action.payload.result;
      })
      .addCase(fetchArchiveSuppliesAsyncAction.fulfilled, (state, action) => {
        state.archiveSupplies = action.payload.result;
      })
      .addCase(fetchSupplyDetailsAsyncAction.fulfilled, (state, action) => {
        state.supply = action.payload.result;
        state.commentFiles = action.payload.files;
      })
      .addCase(fetchStorageAddressesAsyncAction.fulfilled, (state, action) => {
        state.addresses = action.payload.result;
      });
  }
});

export const {
  resetState,
  setSupplyCommentAction,
  setCommentFilesAction,
  setSupplyStorageAction,
  setNewSupplyDateAction,
  setSupplyEmployeeAction,
  setSupplyFormAction
} = supplies.actions;
