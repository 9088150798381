import {useContext} from 'react';
import {useNavigate} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import clsx from 'clsx';

import {resetDocumentAction} from 'redux/slices/documents/documents';

import {getUser} from 'helpers/storage';
import {AppContext} from 'providers/AppContextProvider';

import {AppRoute} from 'constants/routes';
import {
  DocumentTypeButtonsCaptionsMap,
  USER_MANUAL_BUTTON_CAPTION
} from './constants';

import styles from './styles.module.scss';
import {DocumentType} from 'constants/document-type';

const DocumentsListButtonsBlock = ({documentType, withoutNewButton}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userLevel = getUser().accessLevel;

  const ButtonCaption = DocumentTypeButtonsCaptionsMap[documentType];

  const {alert} = useContext(AppContext);

  const handleCreateNewDocumentButtonClick = () => {
    // Для документа инвентаризации сразу переходим на
    // страницу создания нового документа без проверок
    if (documentType === DocumentType.INVENTORY) {
      navigate(AppRoute.Inventory.ADD);
      return;
    }

    // Для документа отгрузки сразу переходим на
    // страницу создания нового документа без проверок
    if (documentType === DocumentType.REALIZATION) {
      navigate(AppRoute.Document.REALIZATIONS_NEW);
      return;
    }

    // Проверить, есть ли в localStorage данные с соответствующим ключом
    const localStorageKey = {
      [DocumentType.CHARGE]: 'newChargeDocument',
      [DocumentType.POSTING]: 'newPostingDocument',
      [DocumentType.SHIFTING]: 'newShiftingDocument',
      [DocumentType.FACTORY]: 'newFactoryDocument',
    };
    const incompletedDocumentAlreadyExists = !!localStorage.getItem(localStorageKey[documentType]);

    // Маршрут перехода на страницу нового документа
    const newDocumentLocation = {
      [DocumentType.CHARGE]: AppRoute.Document.CHARGES_NEW,
      [DocumentType.POSTING]: AppRoute.Document.POSTINGS_NEW,
      [DocumentType.SHIFTING]: AppRoute.Document.SHIFTINGS_NEW,
      [DocumentType.FACTORY]: AppRoute.Document.FACTORIES_NEW
    };

    // Название типа документа в сообщении
    const documentTypeNameMap = {
      [DocumentType.CHARGE]: 'списания',
      [DocumentType.POSTING]: 'оприходования',
      [DocumentType.SHIFTING]: 'перемещения',
      [DocumentType.FACTORY]: 'производства',
    };

    // Если есть, то предложить (задать вопрос) пользователю, хочет ли он его продолжить
    if (incompletedDocumentAlreadyExists) {
      const alertMessage = <span>Ранее вы уже создали, но не сохранили новый<br/>документ {documentTypeNameMap[documentType]}. Продолжить работу с ним?</span>;

      alert(alertMessage, 'default', 0, [
        {
          text: 'Продолжить начатый',
          handler: () => {
            // Очистка стейта
            dispatch(resetDocumentAction([]));

            navigate(newDocumentLocation[documentType]);
            alert('', 'default', 1);
          }
        },
        {
          text: 'Начать заново',
          handler: () => {
            // Очистка localStorage
            localStorage.removeItem(localStorageKey[documentType]);

            // Очистка стейта
            dispatch(resetDocumentAction());

            navigate(newDocumentLocation[documentType]);
            alert('', 'default', 1);
          }
        },
        {
          text: 'Отмена',
          handler: () => alert('', 'default', 1)
        }
      ])
    } else {
      // Очистка стейта
      dispatch(resetDocumentAction());
      navigate(newDocumentLocation[documentType]);
    }
  };

  const handleOpenArchiveButtonClick = () => {
    // Маршрут перехода на страницу архива
    const archiveLocation = {
      [DocumentType.CHARGE]: AppRoute.Archive.CHARGES,
      [DocumentType.POSTING]: AppRoute.Archive.POSTINGS,
      [DocumentType.SHIFTING]: AppRoute.Archive.SHIFTINGS,
      [DocumentType.INVENTORY]: AppRoute.Archive.INVENTORY,
      [DocumentType.REALIZATION]: AppRoute.Archive.REALIZATIONS,
      [DocumentType.SUPPLY]: AppRoute.Archive.SUPPLIES,
      [DocumentType.FACTORY]: AppRoute.Archive.FACTORIES
    };

    window.open(archiveLocation[documentType]);
  };

  const handleOpenUserManualButtonClick = () => {
    // Маршрут перехода на страницу руководства пользователя
    const userManualLocation = {
      [DocumentType.CHARGE]: AppRoute.UserManual.CHARGE,
      [DocumentType.POSTING]: AppRoute.UserManual.POSTING,
      [DocumentType.SHIFTING]: AppRoute.UserManual.SHIFTING,
      [DocumentType.INVENTORY]: AppRoute.UserManual.INVENTORY,
      [DocumentType.REALIZATION]: AppRoute.UserManual.REALIZATION,
      [DocumentType.SUPPLY]: AppRoute.UserManual.SUPPLIES,
      [DocumentType.FACTORY]: AppRoute.UserManual.FACTORY
    };

    window.open(userManualLocation[documentType]);
  };

  return (
    <div className={clsx(styles.wrapper, {
      [styles["without-new-button"]]: withoutNewButton
    })}>
      {/* Кнопка добавления нового документа */}
      {
        userLevel > 1 && !withoutNewButton && (
          <div>
            <button
              className={clsx(styles.button, styles.new)}
              onClick={handleCreateNewDocumentButtonClick}
            >
              <span>
                {ButtonCaption.NEW}
              </span>
            </button>
          </div>
        )
      }

      <div className={styles['inner-wrapper']}>
        {/* Кнопка перехода в соответствующий архив */}
        <div>
          <button
            className={clsx(styles.button, styles.regular)}
            onClick={handleOpenArchiveButtonClick}
          >
            <span>
              {ButtonCaption.ARCHIVE}
            </span>
          </button>
        </div>
        
        {/* Кнопка открытия раздела о перемещениях в руководстве пользователя */}
        <div>
          <button
            className={clsx(styles.button, styles.regular)}
            onClick={handleOpenUserManualButtonClick}
          >
            <span>
              {USER_MANUAL_BUTTON_CAPTION}
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default DocumentsListButtonsBlock;
