import {useContext, useEffect} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import clsx from 'clsx';
import {toast} from 'react-toastify';

import {
  checkIfDenyPossibleAsyncAction,
  deleteRemainsDocumentAsyncAction,
  denyRemainsProvidingAsyncAction,
  downloadRemainsDocumentAsyncAction,
  fetchRemainsDocumentDetailsAsyncAction,
  provideRemainsAsyncAction
} from 'redux/slices/remains/remains-api-actions';
import {
  getDenyPossibilityStatus,
  getRemainsAddresses,
  getRemainsDocument,
  getRemainsProvidedStatus
} from 'redux/slices/remains/selectors';
import {getGoods} from 'redux/slices/good/selectors';

import {AppContext} from 'providers/AppContextProvider';

import {getUser} from 'helpers/storage';

import {AppRoute} from 'constants/routes';

import styles from './styles.module.scss';

const RemainsFloatPanel = ({
  setIsPreloaderActive,
  setCheckValuesModalData,
  setCheckValuesModalActive
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {id} = useParams();

  // Проверка уровня пользователя
  const canUserUpdate = getUser().accessLevel === 3;

  const {alert} = useContext(AppContext);

  const remainsDocument = useSelector(getRemainsDocument);
  const provided = useSelector(getRemainsProvidedStatus);
  const goods = useSelector(getGoods);
  const addresses = useSelector(getRemainsAddresses);
  const providingCanBeDenied = useSelector(getDenyPossibilityStatus);

  // Обработчик кнопки удаления документа
  const handleDeleteDocumentButtonClick = () => {
    // Запрет удаления проведённого документа
    if (provided) {
      return;
    }

    const handler = async () => {
      setIsPreloaderActive(true);
      await dispatch(deleteRemainsDocumentAsyncAction(id));
      navigate(AppRoute.Remains.LIST);
      setIsPreloaderActive(false);
    };

    alert('Удалить документ?', 'danger', 0, [
      {
        text: 'Да',
        handler,
        needToCloseImmediately: true
      },
      {
        text: 'Нет',
        handler: () => alert('', 'default', 1)
      }
    ]);
  };

  // Валидация данных
  const validateRemains = () => {
    const incorrectLines = {};

    const setLineError = (lineNumber, propName) => {
      if (incorrectLines[lineNumber]) {
        incorrectLines[lineNumber][propName] = true;
      } else {
        incorrectLines[lineNumber] = {
          [propName]: true
        };
      }
    };

    const setPositionError = (lineNumber, positionNumber, propName) => {
      if (incorrectLines[lineNumber]) {
        if (incorrectLines[lineNumber].incorrectPositions[positionNumber]) {
          incorrectLines[lineNumber].incorrectPositions[positionNumber][propName] = true;
        } else {
          incorrectLines[lineNumber].incorrectPositions[positionNumber] = {
            [propName]: true
          };
        }
      } else {
        incorrectLines[lineNumber] = {
          incorrectPositions: {
            [positionNumber]: {
              [propName]: true
            }
          }
        };
      }
    };

    remainsDocument.lines.forEach((line, lineIndex) => {
      const lineNumber = lineIndex + 1;
      const isNameInserted = !!line.name;
      const isArticleInserted = !!line.article;

      if (!isNameInserted) {
        setLineError(lineNumber, 'nameNotInserted');
      }
      if (!isArticleInserted) {
        setLineError(lineNumber, 'articleNotInserted');
      }
      if (isNameInserted && isArticleInserted) {
        let goodNotFound = false;

        // поиск товара по имени
        const goodFoundByName = goods.find((good) => good.name === line.name);

        // если найден, то проверить верность артикула
        if (goodFoundByName) {
          const isArticleCorrect = line.article === goodFoundByName.article;

          if (!isArticleCorrect) {
            // если артикул не верный, то вернуть ошибку
            setLineError(lineNumber, 'incorrectArticle');
          }
        } else {
          // если не найден по имени, то искать по артикулу
          const goodFoundByArticle = goods.find((good) => good.article === line.article);

          if (goodFoundByArticle) {
            // если найден по артикулу, то вернуть ошибку "Имя указано не верно"
            setLineError(lineNumber, 'incorrectName');
          } else {
            // иначе установить флаг: "Товар не найден"
            goodNotFound = true;
          }
        }
        
        // если не найден ни по имени, ни по артикулу, то вернуть ошибку "Товар не найден"
        if (goodNotFound) {
          setLineError(lineNumber, 'goodNotFound');
        }
      }

      // Валидация позиций
      line.positions.forEach((position, positionIndex) => {
        const positionNumber = positionIndex + 1;
        const positionAmount = position.amount;
        const positionAddress = position.address;

        // Наличие кол-ва
        if (!positionAmount) {
          setPositionError(lineNumber, positionNumber, 'amountNotInserted');
        } else {
          const AMOUNT_REG_EXP = /^[0-9].+$/;
          const isAmountNumeric = AMOUNT_REG_EXP.test(String(positionAmount));

          if (!isAmountNumeric) {
            setPositionError(lineNumber, positionNumber, 'amountNotNumeric');
          }
        }

        // Наличие и корректность введённого адреса
        if (!positionAddress) {
          setPositionError(lineNumber, positionNumber, 'addressNotInserted');
        } else {
          const isAddressCorrect = addresses.find((address) => address.name === positionAddress);

          if (!isAddressCorrect) {
            setPositionError(lineNumber, positionNumber, 'addressIncorrect');
          }
        }
      });

      // Добавление данных к объекту incorrectLines
      if (incorrectLines[lineNumber]) {
        // Если ошибок наименования нет, то добавляем наименование в объект incorrectLines
        if (!incorrectLines[lineNumber].nameNotInserted && !incorrectLines[lineNumber].incorrectName) {
          incorrectLines[lineNumber].plantName = line.name;
        }
        // Если ошибок артикула нет, то добавляем артикул в объект incorrectLines
        if (!incorrectLines[lineNumber].articleNotInserted && !incorrectLines[lineNumber].incorrectArticle) {
          incorrectLines[lineNumber].plantArticle = line.article;
        }
      }
    });

    if (Object.keys(incorrectLines).length) {
      setCheckValuesModalData({incorrectLines});
      setCheckValuesModalActive(true);
      return false;
    }

    return !Object.keys(incorrectLines).length;
  };

  // Обработчик кнопки проведения в Системе
  const handleProvideDocumentButtonClick = () => {
    const linesFilledCorrectly = validateRemains();

    if (linesFilledCorrectly) {
      const handler = async () => {
        setIsPreloaderActive(true);
        await dispatch(provideRemainsAsyncAction(id));
        dispatch(fetchRemainsDocumentDetailsAsyncAction(id));
        setIsPreloaderActive(false);
      };
  
      alert('Провести в системе?', 'default', 0, [
        {
          text: 'Да',
          handler,
          needToCloseImmediately: true
        },
        {
          text: 'Нет',
          handler: () => alert('', 'default', 1)
        }
      ]);
    }
  };

  // Обработчик отмены проведения документа списания
  const handleDenyRemainsProvidingButtonClick = () => {
    const handler = async () => {
      setIsPreloaderActive(true);
      const denyRemainsResponse = await dispatch(denyRemainsProvidingAsyncAction(id));

      if (denyRemainsResponse.payload.message) {
        toast.error(denyRemainsResponse.payload.message, {
          position: 'bottom-right'
        });
      } else {
        dispatch(fetchRemainsDocumentDetailsAsyncAction(id));
      }

      setIsPreloaderActive(false);
    };

    alert('Отменить проведение в Системе?', 'danger', 0, [
      {
        text: 'Да',
        handler,
        needToCloseImmediately: true
      },
      {
        text: 'Нет',
        handler: () => alert('', 'default', 1)
      }
    ]);
  };

  // Обработчик кнопки скачивания документа
  const handleDownloadDocumentButtonClick = async () => {
    const downloadResponse = await dispatch(downloadRemainsDocumentAsyncAction(id));

    window.open(
      `${process.env.REACT_APP_SERVER_URL}/FILES/${downloadResponse.payload.filename}`,
      "_blank"
    );
  };

  const tmpButtonHandler = () => toast.success('Кнопка временно не работает', {
    position: 'bottom-right'
  });

  // Запрос данных по id документа о состоянии истории товаров, находящихся в документе
  // Если по какому-либо из них было любое другое движение уже после загрузки текущих
  // остатков, то кнопка отмены проведения текущего документа д.б. заблокирована
  // (полученная информация о состоянии хранится в переменной providingCanBeDenied)
  useEffect(() => {
    if (!!provided) {
      dispatch(checkIfDenyPossibleAsyncAction(id));
    }
  }, [provided]);

  return (
    <div className={clsx(styles.floatPanel, styles.controlsPanel)}>
      {/* Кнопка удаления документа */}
      {
        id && canUserUpdate && !provided && (
          <span
            className="roundIcon dangerIcon material-icons"
            onClick={handleDeleteDocumentButtonClick}
            title="Удалить"
          >
            delete_sweep
          </span>
        )
      }

      {/* Кнопка проведения документа в системе */}
      {
        id && canUserUpdate && !provided && (
          <span
            className="roundIcon material-icons"
            onClick={handleProvideDocumentButtonClick}
            title="Провести в Системе"
          >
            checklist
          </span>
        )
      }

      {/* Кнопка отмены проведения */}
      {
        id && canUserUpdate && !!provided && providingCanBeDenied && (
          <span
            className="roundIcon material-icons dangerIcon"
            onClick={handleDenyRemainsProvidingButtonClick}
            title="Отменить проведение"
          >
            undo
          </span>
        )
      }

      {/* Кнопка скачивания документа */}
      {
        id && (
          <span
            className="roundIcon material-icons"
            onClick={handleDownloadDocumentButtonClick}
            title="Скачать"
          >
            download
          </span>
        )
      }

      {/* Кнопка перехода в руководство пользователя */}
      <span
        className="roundIcon material-icons"
        onClick={tmpButtonHandler}
        title="Инструкция"
      >
        quiz
      </span>
    </div>
  );
};

export default RemainsFloatPanel;
