import {createAsyncThunk} from '@reduxjs/toolkit';

import {ApiRoute} from 'constants/endpoints';
import {DocumentType} from 'constants/document-type';

export const fetchDocumentsAsyncAction = createAsyncThunk(
  'documents/fetchDocuments',
  async (args, {extra: api}) => {
    const isInArchive = args.isInArchive;
    const documentType = args.documentType;
    const archiveDocumentsQueryString = isInArchive ? '&done=1' : '';
    const {data} = await api.get(`${ApiRoute.Fields.GET_FORMS}?type=${documentType}${archiveDocumentsQueryString}`);

    return data;
  }
);

export const fetchDocumentsNumbersAsyncAction = createAsyncThunk(
  'documents/fetchChargeDocumentsNumbers',
  async (documentType, {extra: api}) => {
    const regularDocuments = await api.get(`${ApiRoute.Fields.GET_FORMS}?type=${documentType}`);
    const archiveDocuments = await api.get(`${ApiRoute.Fields.GET_FORMS}?type=${documentType}&done=1`);

    const result = [...regularDocuments.data.result, ...archiveDocuments.data.result].map((document) => document.document_number);

    return result;
  }
);

export const fetchDocumentAsyncAction = createAsyncThunk(
  'documents/fetchDocument',
  async (id, {extra: api}) => {
    const {data} = await api.get(`${ApiRoute.Fields.GET_FORM}/${id}`);

    return data;
  }
);

export const postDocumentAsyncAction = createAsyncThunk(
  'documents/postDocument',
  async (body, {extra: api}) => {
    const {data} = await api.post(ApiRoute.Fields.SAVE_FORM, body);

    return data;
  }
);

export const updateDocumentAsyncAction = createAsyncThunk(
  'documents/updateDocument',
  async (args, {extra: api}) => {
    const id = args.id;
    const body = args.body;
    const {data} = await api.post(`${ApiRoute.Fields.UPDATE_FORM}/${id}`, body);

    return data;
  }
);

export const deleteDocumentAsyncAction = createAsyncThunk(
  'documents/deleteDocument',
  async (id, {extra: api}) => {
    const {data} = await api.get(`${ApiRoute.Fields.DELETE_FORM}/${id}`);

    return data;
  }
);

export const setNewDocumentInitialAmountsAsyncAction = createAsyncThunk(
  'documents/setNewDocumentInitialAmounts',
  async (body, {extra: api}) => {
    const {data} = await api.post(ApiRoute.Fields.SET_INITIAL_HISTORY_AMOUNTS, body);

    return {data, plantName: body.name};
  }
);

export const fetchShiftingTargetAddressesInitialAmountsAsyncAction = createAsyncThunk(
  'documents/fetchShiftingTargetAddressesInitialAmounts',
  async (body, {extra: api}) => {
    const {data} = await api.post(ApiRoute.Fields.SET_INITIAL_HISTORY_AMOUNTS, body);

    return {data, plantName: body.name};
  }
);

export const refreshDocumentAmountsAsyncAction = createAsyncThunk(
  'documents/refreshDocumentAmounts',
  async (args, {extra: api}) => {
    const id = args.id;
    const documentType = args.documentType;

    if (documentType === DocumentType.CHARGE) {
      await api.post(`${ApiRoute.Fields.REFRESH_AMOUNTS}/${id}`);
    }
    if (documentType === DocumentType.POSTING) {
      await api.post(`${ApiRoute.Fields.REFRESH_POSTING_DOCUMENT_AMOUNTS}/${id}`);
    }
  }
);

export const provideDocumentAsyncAction = createAsyncThunk(
  'documents/provideDocument',
  async (id, {extra: api}) => {
    const {data} = await api.get(`${ApiRoute.Fields.PROVIDE_FORM}/${id}`);

    return data;
  }
);

export const denyProvidingDocumentAsyncAction = createAsyncThunk(
  'documents/denyProvidingDocument',
  async (id, {extra: api}) => {
    const {data} = await api.get(`${ApiRoute.Fields.PROVIDE_FORM}/${id}?deny=1`);

    return data;
  }
);

export const provideInBruAsyncAction = createAsyncThunk(
  'documents/provideInBru',
  async (id, {extra: api}) => {
    const {data} = await api.get(`${ApiRoute.Fields.PROVIDE_FORM}/${id}?done=1`);

    return data;
  }
);

export const makeDocumentCorrectionAsyncAction = createAsyncThunk(
  'documents/makeChargeDocumentCorrection',
  async (args, {extra: api}) => {
    const id = args.id;
    const withHistory = args.withHistory;
    const historyQuery = withHistory ? '&correction_history=1' : '';
    const {data} = await api.get(`${ApiRoute.Fields.PROVIDE_FORM}/${id}?correction=1${historyQuery}`);

    return data;
  }
);

export const fetchCorrectionsAsyncAction = createAsyncThunk(
  'documents/fetchCorrections',
  async (_args, {extra: api}) => {
    const {data} = await api.get(`${ApiRoute.Fields.GET_FORMS}?correction=1&done=1`);

    return data;
  }
);

export const postCommentFilesAsyncAction = createAsyncThunk(
  'documents/postCommentFiles',
  async (args, {extra: api}) => {
    const id = args.id;
    const files = args.files;

    await api.post(`${ApiRoute.Fields.SEND_COMMENT_FILES}/${id}`, files);
  }
);

export const deleteCommentFileAsyncAction = createAsyncThunk(
  'documents/deleteCommentFile',
  async (file, {extra: api}) => {
    await api.post(ApiRoute.Fields.DELETE_COMMENT_FILE, file);
  }
);

export const fetchGoodsByStorageAsyncAction = createAsyncThunk(
  'documents/fetchGoodsByStorage',
  async (storage, {extra: api}) => {
    const {data} = await api.get(`${ApiRoute.Fields.GET_GOODS_BY_STORAGE}?storage=${storage}`);

    return data;
  }
);

export const fetchGoodsByStorageFromHistoryAsyncAction = createAsyncThunk(
  'documents/fetchGoodsByStorageFromHistory',
  async (args, {extra: api}) => {
    const date = args.date;
    const storage = args.storage;
    const {data} = await api.get(`${ApiRoute.Fields.GET_GOODS_BY_STORAGE_FROM_HISTORY}?storage=${storage}&date=${date}`);

    return data;
  }
);

export const fetchStorageAddressesAsyncAction = createAsyncThunk(
  'documents/fetchStorageAddresses',
  async (storage, {extra: api}) => {
    const {data} = await api.get(ApiRoute.Map.GET_ALL_ADDRESSES);

    return data;
  }
);

export const copyFormFilesAsyncAction = createAsyncThunk(
  'documents/copyFormFiles',
  async (args, {extra: api}) => {
    await api.post(ApiRoute.Fields.COPY_FORM_FILES, args);
  }
);

export const downloadDocumentAsyncAction = createAsyncThunk(
  'documents/downloadDocument',
  async (id, {extra: api}) => {
    const {data} = await api.get(`${ApiRoute.Fields.DOWNLOAD}/${id}`);

    return data;
  }
);
